import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select, Input } from "antd";
import Button from "../../Layout/DesignSystem/Button/Button";
import { getRepositoryProjects } from "../../RepositoryProjects/Application/RepositoryProjects.business";
import { getRepositoryProjectsSelector } from "../../RepositoryProjects/Infrastructure/RepositoryProjects.reducer";

const { Option } = Select;

const NewRepositoryProjects = (props) => {
  const repositoryProjects = useSelector(getRepositoryProjectsSelector);
  const [selectedRepositoryProject, setSelectedRepositoryProject] =
    useState("");
  const [searchValue, setSearchValue] = useState("");
  const [emissionValue, setEmissionValue] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    const isButtonDisabled =
      props.userId === undefined ||
      !searchValue ||
      isNaN(parseFloat(emissionValue)) ||
      emissionValue === null;
    setButtonDisabled(isButtonDisabled);
  }, [props.userId, searchValue, emissionValue]);

  useEffect(() => {
    getRepositoryProjects();
  }, []);

  const onSearchRepositoryProjects = (val) => {
    getRepositoryProjects({ filter: { q: val } });
  };

  const handleClick = () => {};

  const handleSelectChange = (value, option) => {
    setSelectedRepositoryProject(option.children);
    setSearchValue(value);
  };

  return (
    <div className="grid-NewManageData">
      <div className="item-NewManageData">
        <Select
          onSearch={onSearchRepositoryProjects}
          showSearch
          placeholder="Seleccionar Proyectos"
          style={{ width: "100%" }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={searchValue}
          onChange={(value, option) => handleSelectChange(value, option)}
        >
          {repositoryProjects?.map((item) => (
            <Option value={item.id} key={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="item-NewManageData">
        <Input
          type="number"
          min={0}
          className="input-NewManageData custom-input"
          placeholder="Emisión(es)"
          value={emissionValue}
          onChange={(e) => setEmissionValue(e.target.value)}
        />
      </div>
      <div className="item-NewManageData">
        <Button
          htmlType="submit"
          text="Agregar"
          block={true}
          onClick={handleClick}
          disabled={buttonDisabled}
        />
      </div>
    </div>
  );
};

export default NewRepositoryProjects;
