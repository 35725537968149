import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getDocumentById(id) {
  return await new API(URL.setId(URL.buildUrl("documents"), id)).get();
}

export async function PostDocument(document) {
  return await new API(URL.buildUrl("documents")).post({ document });
}

export async function getByCompany(id) {
  return await new API(URL.buildUrl(`companies/${id}/documents`)).get();
}

export async function deleteDocument(id) {
  return await new API(URL.setId(URL.buildUrl("documents"), id)).delete();
}

export async function updateStatusDocument(id, data) {
  const url = URL.setId(URL.buildUrl("documents"), id);
  return await new API(url).put(data);
}
export async function getByUser(userId, params) {
  const url = URL.setParams(URL.buildUrl(`users/${userId}/documents`), params);

  return await new API(url).get();
}

export async function getByAll(type, params) {
  const url = URL.setParams(URL.buildUrl(`documents`), params);

  return await new API(url).get();
}
