import { useState, useEffect } from "react";
import InterfaceTable from "../../Table/InterfaceTable";
import { message, Space } from "antd";
import Formatter from "../../Data/Domain/Formatter";
import { deleteTransportType } from "../Application/TransportTypes.business";
import TransportTypesDetail from "./TransportTypesDetail";
import { ModalDeleteConfirm, DeleteIcon } from "../../Utils/UI/Text";

const TransportTypesBoard = ({
  transportTypes,
  getTransportTypes,
  totalCountTransportTypes,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [filters, setFilters] = useState({});

  const handleDelete = (id) => {
    ModalDeleteConfirm({
      title: "¿Está seguro de que desea eliminar este transporte?",
      onConfirm: async () => {
        try {
          await deleteTransportType(id);
        } catch (error) {
          message.error("Se produjo un error, vuelva a intentarlo.");
        }
      },
      messageOnOk: "El transporte ha sido eliminado correctamente.",
      onCancel: () => {},
    });
  };

  const columnsTransportTypes = [
    {
      title: "Clasificación en doc ",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Clasificación tipo transporte",
      dataIndex: "classification",
      key: "classification",
    },
    {
      title: "Tipo de transporte",
      dataIndex: "transportType",
      key: "transportType",
    },
    {
      title: "Año FE",
      dataIndex: "FE_year",
      key: "FE_year",
    },
    {
      title: "FE",
      dataIndex: "FE",
      key: "FE",
    },
    {
      title: "Unidades",
      dataIndex: "units",
      key: "units",
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <TransportTypesDetail record={record} refreshData={fetchData} />
          <DeleteIcon
            TooltipDelete="Eliminar este transporte"
            onClick={() => handleDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);

      const finalParams = {
        limit: values.limit,
        page: values.page,
        ...params,
      };

      await getTransportTypes(finalParams);
      setLoading(false);
    } catch (error) {
      message.error("Error al cargar los datos");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, newFilters = {}, sorter = {}) => {
    const { current = 1, pageSize = values.limit } = newPagination;

    const updatedValues = {
      page: current - 1,
      limit: pageSize,
    };
    setValues(updatedValues);

    const cleanParams = Formatter.cleanParamsFromTableToAPI({
      ...newFilters,
      limit: updatedValues.limit,
      page: updatedValues.page,
      dates: [],
    });

    setFilters(newFilters);
    fetchData(cleanParams);
  };

  useEffect(() => {
    fetchData({
      limit: values.limit,
      page: values.page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          total: totalCountTransportTypes,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            const updatedValues = {
              page: current - 1,
              limit: size,
            };
            setValues(updatedValues);
            fetchData({
              page: updatedValues.page,
              limit: updatedValues.limit,
            });
          },
        }}
        data={transportTypes}
        columns={columnsTransportTypes}
        loading={loading}
      />
    </>
  );
};

export default TransportTypesBoard;
