import React, { useState, useEffect } from "react";
import { Button, Row, Col, Select, Form, Input, message } from "antd";
// import { useDispatch } from "react-redux";
import { getProjectIpfs } from "../../Projects/Application/Projects.business";
import { postIpfs } from "../../Projects/Infrastructure/Projects.service";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./Owners.css";
import Modal from "../../Modal/Modal";

const NewIPFSPage = ({ recordId }) => {
  // const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: "",
    description: "",
    hash: "",
    url: "",
    type: "",
    project: recordId,
  });

  const inputFolderName = (type) => {
    let folderName = "";

    switch (type) {
      case "dmrv":
        folderName = "DMRV";
        break;
      case "setup":
        folderName = "Setup";
        break;
      case "baseline":
        folderName = "Baseline";
        break;
      default:
        folderName = "";
        break;
    }

    setValues((prevValues) => ({
      ...prevValues,
      name: folderName,
    }));
  };

  useEffect(() => {
    getProjectIpfs(recordId);
  }, [recordId]);

  const onHandleChange = (input) => (e) => {
    setValues({
      ...values,
      [input]: e.target.value,
    });
  };

  const handleSelectorChange = (value) => {
    setValues({
      ...values,
      type: value,
    });
    inputFolderName(value);
  };

  const handleSubmit = async () => {
    const { name, type, description, hash, url } = values;

    try {
      await postIpfs({
        name,
        type,
        description,
        hash,
        url,
        project: values.project,
      });
      message.success("Se agregó una nueva carpeta");
      setValues({
        ...values,
        name: "",
        description: "",
        hash: "",
        url: "",
        type: "",
      });
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
    }
    // dispatch(getProjectIpfs(recordId));
  };

  return (
    <div>
      <Modal
        children={
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            style={{ maxWidth: "100%", margin: "auto" }}
          >
            <Row style={{ marginBottom: "20px" }}>
              <Col span={7}>
                <label htmlFor="descripcion">Tipo:</label>
                <Select
                  label={"Scope"}
                  style={{ width: "95%" }}
                  options={[
                    { value: "dmrv", label: "dmrv" },
                    { value: "setup", label: "setup" },
                    { value: "baseline", label: "baseline" },
                  ]}
                  onChange={handleSelectorChange}
                  value={values.type}
                />
              </Col>
              <Col span={17}>
                <label htmlFor="descripcion">Nombre:</label>
                <Input
                  placeholder=""
                  value={values.name}
                  onChange={onHandleChange("name")}
                />
              </Col>
            </Row>

            <Row style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <label htmlFor="descripcion">Descripción:</label>
                <Input.TextArea
                  id="descripcion"
                  value={values.description}
                  onChange={onHandleChange("description")}
                  style={{ width: "100%" }}
                  rows={4}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <label htmlFor="hash">Hash:</label>
                <Input
                  id="hash"
                  value={values.hash}
                  onChange={onHandleChange("hash")}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <label htmlFor="url">URL:</label>
                <Input
                  id="url"
                  value={values.url}
                  onChange={onHandleChange("url")}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: "20px" }}>
              <Col span={24}>
                <Button type="primary" htmlType="submit" block>
                  Agregar
                </Button>
              </Col>
            </Row>
          </Form>
        }
        icon={<PlusCircleOutlined className="icon-style" />}
        className="icon-style"
        title="Crear una carpeta"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default NewIPFSPage;
