import React, { useEffect, useState } from "react";
import { Spin, Typography, Badge, Avatar } from "antd";
import Storage from "../../Data/Domain/Storage";
import { getUserRepository } from "../Infrastructure/Users.repository";
import { getAdminRepository } from "../../Administrator/Infrastructure/Administrators.repository";
import "./Users.css";
import { role as getRoleFromAPI } from "../../Login/Application/Auth.logic";
import USER from "../Domain/User";
import { useDispatch } from "react-redux";
import { setLocalRoles } from "../Infrastructure/Users.reducer";
import User from "../Domain/User";

const { Text } = Typography;
const styles = {
  avatar: {
    borderRadius: "50%",
    border: "4.7px solid transparent",
    borderTopColor: "#00DD9E",
    borderLeftColor: "#1FE1AA	",
    borderRightColor: "#1FE1AA	",
    backgroundImage: "#00DD9E",
    backgroundClip: "padding-box, border-box",
    backgroundOrigin: "padding-box, border-box",
    backgroundSize: "100% 100%, 100% 50%",
    backgroundPosition: "center left, center top",
    backgroundRepeat: "no-repeat",
  },
  avatarBorderNone: {
    borderRadius: "50%",
  },
  badge: {
    width: "11px",
    height: "11px",
    boxShadow: "0 0 0 5px #fff",
    backgroundColor: "#00DD9E",
  },
};
export default function UserReferenceField(props) {
  const { id, dashboard = false } = props;
  const [user, setUser] = useState(null);
  const [roleObject, setRoleObject] = useState(null);
  const storedRol = new Storage("rol").getItem();
  if (!storedRol || typeof storedRol !== "string") {
    new Storage("rol").setItem("defaultRole");
  }
  const [selectedRol, setSelectedRol] = useState(() => {
    return storedRol ? storedRol : null;
  });
  const dispatch = useDispatch();

  async function getDataForUser() {
    const internal_id = id || new Storage("id").getItem();
    const role_string = new Storage("rol").getItem();

    if (role_string === User.PARTNER || (!dashboard && id)) {
      return await getUserRepository(internal_id);
    }
    if (
      role_string === User.ADMIN ||
      role_string === User.PROJECTS_VIEWER ||
      role_string === User.PROJECTS_SELLER ||
      role_string === User.CARBON_CREDITS ||
      role_string === User.CARBON_MANAGEMENT ||
      role_string === User.OWNER ||
      dashboard
    ) {
      return await getAdminRepository(internal_id);
      // ;
    }
  }

  async function fetchRole() {
    const role_object = await getRoleFromAPI();
    const translatedUserRoles = role_object.role.map((role) => {
      const translatedRoles = USER.ROLES[role] || role;
      return { roleName: role, translatedRoles: translatedRoles };
    });
    setRoleObject({ ...role_object, role: translatedUserRoles });
    return role_object;
  }

  useEffect(() => {
    getDataForUser().then((user) => setUser(user));
    fetchRole().then((userRole) => {
      let selectRole = storedRol ? storedRol : userRole.role[0];
      setSelectedRol(selectRole);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const storage = new Storage("rol");
  const handleRoleClick = async (role) => {
    try {
      const role_object = await fetchRole();
      const rol = role_object.role.find((r) => r === role);
      setSelectedRol(rol);
      dispatch(setLocalRoles(rol));
      storage.setItem(rol);
      return rol;
    } catch (error) {}
  };

  return (
    <>
      {user ? (
        <div className={props.container}>
          <Badge dot={props.dot} offset={["-5%", "85%"]} style={styles.badge}>
            <Avatar
              size={props.size}
              src={user.profile_picture}
              shape="circle"
              // style={styles.avatar}
              style={
                props.size === 50 ? styles.avatarBorderNone : styles.avatar
              }
              alt={`${user.userName} Avatar`}
            />
          </Badge>
          <div className={props.containerRight}>
            <div className={props.containerText}>
              <Text className={props.stylesUsername}>{user.userName}</Text>
              {/* <Text className={props.stylesUsername}>{user.username}</Text> */}

              <Text className={props.occupationStyle}>{props.occupation} </Text>
            </div>
            {/* <div className={"children"}>{props.children}</div> */}

            <div className={"dropdown"}>
              {props.dropdown ? (
                <>{props.dropdown}</>
              ) : (
                <div className={"children"}>
                  {typeof props.children === "function"
                    ? props.children({
                        roleObject,
                        selectedRol,
                        handleRoleClick,
                      })
                    : props.children}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Spin />
      )}
    </>
  );
}
