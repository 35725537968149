import { Select } from "antd";
import { useEffect, useState } from "react";
import { getSubcategories } from "../Application/Subcategories.business";
import { useSelector } from "react-redux";
import { getSubcategoriesSelector } from "../Infrastructure/Subcategories.reducer";

const { Option } = Select;

const AllSubcategoriesSelect = ({ setItems }) => {
  const subcategories = useSelector(getSubcategoriesSelector);
  const [filter, setFilter] = useState({});

  useEffect(() => {
    getSubcategories({});
  }, []);

  const handleSubcategoryChange = (value) => {
    setItems(value);
  };

  const handleScopeChange = (value) => {
    const newFilter = { ...filter, scope: value };
    getSubcategories({ filter: newFilter });
    setFilter(newFilter);
  };

  const handleSearch = (newValue) => {
    const newFilter = { ...filter, q: newValue };
    getSubcategories({ filter: newFilter });
    setFilter(newFilter);
  };

  return (
    <>
      <Select
        defaultValue={"scope 1"}
        style={{ width: "50%" }}
        onChange={handleScopeChange}
      >
        <Option value={"scope 1"}>Scope 1</Option>
        <Option value={"scope 2"}>Scope 2</Option>
        <Option value={"scope 3"}>Scope 3</Option>
      </Select>
      <Select
        showSearch
        mode="multiple"
        allowClear
        placeholder={"Subcategorías"}
        style={{ width: "50%" }}
        onChange={handleSubcategoryChange}
        onSearch={handleSearch}
        filterOption={false}
      >
        {subcategories?.map((subcategory) => (
          <Option value={subcategory.id} key={subcategory.id}>
            {`${subcategory.name} | (${subcategory.aliases})`}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default AllSubcategoriesSelect;
