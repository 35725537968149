import { useState, useEffect } from "react";
import Modal from "../../Modal/Modal";
import { Input, message, Form } from "antd";
import { EditIcon } from "../../Utils/UI/Text";
import { updateTransportType } from "../Application/TransportTypes.business";
import CustomError from "../../Utils/Domain/CustomError";

const TransportTypesDetail = ({ record, refreshData }) => {
  const [values, setValues] = useState(record);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setValues(record);
  }, [record]);

  const onHandleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      description: values.description,
      classification: values.classification,
      transportType: values.transportType,
      FE_year: values.FE_year,
      FE: values.FE,
      units: values.units,
    };

    try {
      await updateTransportType(record.id, data);
      message.success("Tipo de transporte actualizado con éxito");
      refreshData();
      updateTransportType(record.id, data);
      return true;
    } catch (error) {
      const customError = new CustomError(error);

      message.error(customError.message);

      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title="Editar tipo de transporte"
        icon={<EditIcon TooltipEdit="Editar este transporte" />}
        cancelButtonProps={{ style: { display: "none" } }}
        text="Editar"
        handleSubmit={handleUpdate}
        form={form}
      >
        <Form
          name="basic"
          autoComplete="off"
          layout="vertical"
          responsive={"true"}
          initialValues={values}
          onFinish={handleUpdate}
        >
          {record && (
            <>
              <div className="form-group-transportTypes">
                <label
                  htmlFor="description"
                  className="form-label-transportTypes"
                >
                  Clasificación en doc:
                </label>
                <Input
                  placeholder="Clasificación en doc"
                  id="description"
                  name="description"
                  value={values?.description || ""}
                  onChange={onHandleChange}
                  className="input-style-transportTypes"
                />
              </div>

              <div className="form-group-transportTypes">
                <label
                  htmlFor="classification"
                  className="form-label-transportTypes"
                >
                  Clasificación tipo transporte:
                </label>
                <Input
                  placeholder="Clasificación tipo transporte"
                  id="classification"
                  name="classification"
                  value={values?.classification || ""}
                  onChange={onHandleChange}
                  className="input-style-transportTypes"
                />
              </div>

              <div className="form-group-transportTypes">
                <label
                  htmlFor="transportType"
                  className="form-label-transportTypes"
                >
                  Tipo de transporte:
                </label>
                <Input
                  placeholder="Tipo de transporte"
                  id="transportType"
                  name="transportType"
                  value={values?.transportType || ""}
                  onChange={onHandleChange}
                  className="input-style-transportTypes"
                />
              </div>

              <div className="form-group-transportTypes">
                <label htmlFor="FE_year" className="form-label-transportTypes">
                  Año FE:
                </label>
                <Input
                  placeholder="Año FE"
                  id="FE_year"
                  name="FE_year"
                  value={values?.FE_year || ""}
                  onChange={onHandleChange}
                  className="input-style-transportTypes"
                />
              </div>

              <div className="form-group-transportTypes">
                <label htmlFor="FE" className="form-label-transportTypes">
                  FE:
                </label>
                <Input
                  placeholder="FE"
                  id="FE"
                  name="FE"
                  value={values?.FE || ""}
                  onChange={onHandleChange}
                  className="input-style-transportTypes"
                />
              </div>

              <div className="form-group-transportTypes">
                <label htmlFor="units" className="form-label-transportTypes">
                  Unidades:
                </label>
                <Input
                  placeholder="Unidades"
                  id="units"
                  name="units"
                  value={values?.units || ""}
                  onChange={onHandleChange}
                  className="input-style-transportTypes"
                />
              </div>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default TransportTypesDetail;
