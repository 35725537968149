import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Badge,
  Popconfirm,
  message,
  PageHeader,
  Descriptions,
  Tag,
  Row,
  Col,
  Select,
  Modal,
} from "antd";
import {
  LinkOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import "antd/dist/antd.min.css";
import "./Users.css";
import { resetPassword } from "../../Passwords/Infrastructure/Password.service";
import { getSelectedUserSelector } from "../Infrastructure/Users.reducer";
import { deselectUser } from "../Infrastructure/Users.store";
import URL from "../../Data/Domain/URL";
import {
  deleteUser,
  updateEnabledUser,
  updateLumens,
  updateUserStatus,
} from "../Application/Users.business";
import Button from "../../Layout/DesignSystem/Button/Button";

const { Option } = Select;

const tagText = {
  fontFamily: "MontserratMedium",
  fontSize: "12px",
  fontWeight: 500,
};

class Translator {
  DICT() {
    return {
      "Invest for profit": "Invertir con ánimo de lucro",
      "Environmental inpact": "Impacto ambiental",
      "Help green projects": "Ayudar proyectos verdes",
      "My job income": "Mi ingreso laboral",
      "Real estates": "Bienes raíces",
      "Investment Family": "Familia de inversión",
      "Independent contractor": "Contratista independiente",
      Payroll: "Nómina",
      Student: "Estudiante",
      Unemployment: "Desempleo",
      Other: "Otro",
    };
  }

  translate(key) {
    return this.DICT()[key] || key;
  }
}

export default function UsersForm({ getUser }) {
  const selected = useSelector(getSelectedUserSelector);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(null);

  const translator = new Translator();

  useEffect(() => {
    if (selected) {
      setUser(selected);
      setStatus(selected.verified);
    }
  }, [selected]);

  const navigate = useNavigate();

  const cancel = () => {
    deselectUser();
    navigate("/users");
  };

  const onFinish = () => {
    resetPassword(selected.id)
      .then(() => message.success("Contraseña solicitada con éxito"))
      .catch(() => message.error("Algo falló"));
  };

  const [key, setKey] = useState(null);
  useEffect(() => {
    setKey(key);
  }, [key]);

  const handleModalOk = () => {
    return Modal.info({
      title: "Se eliminó la cuenta de usuario",
      okType: "danger",
      onOk() {},
    });
  };

  const handleDelete = async (keyValue) => {
    try {
      setKey(keyValue);

      if (keyValue === "delete") {
        Modal.confirm({
          title: "Vas a eliminar un usuario",
          icon: <ExclamationCircleFilled />,
          content:
            "¿Estás seguro que quieres eliminar permanentemente este usuario?",
          okText: "Si",
          okType: "danger",
          onOk: () => {
            deleteUser(selected.id, {
              op: keyValue,
            });
            handleModalOk();
            message.success("Se eliminó la cuenta de usuario");
          },
        });
      } else if (keyValue === "disable") {
        Modal.confirm({
          title: "Está seguro de que deseas deshabilitar a este usuario?",
          okText: "Si",
          okType: "danger",
          onOk: () => {
            deleteUser(selected.id, {
              op: keyValue,
            });
            message.success("Se deshabilitó la cuenta de usuario");
          },
        });
      } else if (keyValue === "enable") {
        const data = {
          enabled: true,
        };
        Modal.confirm({
          title: "Está seguro de que deseas habilitar a este usuario?",
          okText: "Si",
          okType: "danger",
          onOk: () => {
            updateEnabledUser(selected.id, data);
            message.success("Se habilitó la cuenta de usuario");
          },
        });
      }
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const handleLumens = async () => {
    const lumens = { lumens: 5 };
    try {
      await updateLumens(user.id, lumens);
      getUser(user.id);
      message.success("Lumens cargados con éxito");
    } catch (error) {
      message.error("Se produjo un error al cargar Lumens.");
    }
  };

  const handleStatusChange = async (value) => {
    try {
      await updateUserStatus(selected.id, value);
      setStatus(value);
      message.success("Estado de verificación actualizado con éxito");
      getUser(selected.id);
    } catch (error) {
      message.error("Error al actualizar el estado de verificación");
    }
  };

  return selected ? (
    <div>
      <PageHeader
        onBack={cancel}
        className="site-page-header"
        tags={
          <Tag color="green" style={tagText}>
            {selected.fullName}
          </Tag>
        }
        extra={[]}
        avatar={{
          src: selected.profile_picture,
        }}
      >
        <div className="grid-user">
          <div className="item-user">
            <Popconfirm
              title="¿Estas seguro que quieres solicitar una contraseña nueva?"
              onConfirm={onFinish}
              okText="Solicitar Contraseña"
              cancelText="Cancelar"
            >
              <Button htmlType="button" text={"Solicitar contraseña"} />
            </Popconfirm>
          </div>
          <div className="item-user">
            <Button
              htmlType="button"
              text={"Cargar Lumens"}
              onClick={handleLumens}
            />
          </div>
          <div className="item-user">
            <Select
              className="select-delete ant-select-selector"
              defaultValue="Selecciona una opción"
              onChange={handleDelete}
            >
              {selected.enabled === true ? (
                <Option key="disable" disabled={selected.disabledValue}>
                  Deshabilitar usuario
                </Option>
              ) : (
                <Option key="enable" disabled={selected.disabledValue}>
                  Habilitar usuario
                </Option>
              )}
              <Option key="delete" disabled={selected.disabledValue}>
                Eliminar usuario{" "}
                <DeleteOutlined
                  class="icon"
                  style={{ color: "red", marginLeft: "6px" }}
                />
              </Option>
            </Select>
          </div>
        </div>
        ,
        <Descriptions title="" layout="vertical" bordered>
          <Descriptions.Item label="Nombre" span={2}>
            {selected.name}
          </Descriptions.Item>
          <Descriptions.Item label="Apellido" span={1}>
            {selected.lastname}
          </Descriptions.Item>
          <Descriptions.Item label="Fecha de nacimiento" span={1}>
            {selected.extras?.personal_day &&
            selected.extras?.personal_month &&
            selected.extras?.personal_year
              ? `${selected.extras.personal_day}/${selected.extras.personal_month}/${selected.extras.personal_year}`
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Email" span={2}>
            {selected.email}
          </Descriptions.Item>
          <Descriptions.Item label="Ciudad">
            {selected.extras?.locality || ""}
          </Descriptions.Item>
          <Descriptions.Item label="País">
            {selected.extras?.country || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Código Postal">
            {selected.extras?.postalCode || ""}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Select
              value={status}
              onChange={handleStatusChange}
              className="custom-select"
              style={{ width: "100%" }}
            >
              <Option value="starter">Inicial</Option>
              <Option value="data_loaded">Data Cargada</Option>
              <Option value="pending">Pendiente</Option>
              <Option value="succeeded">Verificado</Option>
              <Option value="rejected">Rechazado</Option>
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label="Activo">
            <Badge status="processing" text={selected.enabledValue} />
          </Descriptions.Item>
          <Descriptions.Item label="Withdrew Usd">
            {selected.withdrew_usd}
          </Descriptions.Item>
          <Descriptions.Item label="Tipo de Cuenta" span={3}>
            {selected.extras?.type || ""}
          </Descriptions.Item>
          <Descriptions.Item
            label="¿Por qué estás interesado en eGreen?"
            span={3}
          >
            {selected.extras?.whyEgreen
              ? translator.translate(selected.extras.whyEgreen)
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Origen de los recursos" span={1}>
            {selected.extras?.sourceIncome
              ? translator.translate(selected.extras.sourceIncome)
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Estatus de empleo" span={2}>
            {selected.extras?.employmentStatus
              ? translator.translate(selected.extras.employmentStatus)
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Public Key">
            <Row wrap={false}>
              <Col flex="auto">{selected.publicKey}</Col>
              <Col flex="none">
                <div style={{ padding: "0 16px" }}>
                  <a
                    href={`${URL.STELLAR_URL}${selected.publicKey}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkOutlined />
                  </a>
                </div>
              </Col>
            </Row>
          </Descriptions.Item>
        </Descriptions>
        ,
      </PageHeader>
      ,
    </div>
  ) : (
    <div />
  );
}
