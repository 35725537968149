import { useState, useEffect } from "react";
import Modal from "../../Modal/Modal";
import { Input, message, Form } from "antd";
import { EditIcon } from "../../Utils/UI/Text";
import { updateCostCenter } from "../Application/CostCenters.business";
import CustomError from "../../Utils/Domain/CustomError";

const CostCentersDetail = ({ record, refreshData }) => {
  const [values, setValues] = useState(record);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setValues(record);
  }, [record]);

  const onHandleChange = (e) => {
    setValues((oldValues) => ({
      ...oldValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      code: values.code,
      area: values.area,
      project: values.project,
    };

    try {
      await updateCostCenter(record.id, data);
      message.success("Centro de costo actualizado con éxito");
      refreshData();
      updateCostCenter(record.id, data);
      return true;
    } catch (error) {
      const customError = new CustomError(error);

      message.error(customError.message);

      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        title="Editar un centro de costo"
        icon={<EditIcon TooltipEdit="Editar un centro de costo" />}
        cancelButtonProps={{ style: { display: "none" } }}
        text="Editar"
        handleSubmit={handleUpdate}
        form={form}
      >
        <Form
          name="basic"
          autoComplete="off"
          layout="vertical"
          responsive={"true"}
          initialValues={values}
          onFinish={handleUpdate}
        >
          {" "}
          {record && (
            <>
              <div className="form-group-costCenters">
                <label htmlFor="code" className="form-label-costCenters">
                  Código Centro de Costo:
                </label>
                <Input
                  placeholder="Código Centro de Costo"
                  id="code"
                  name="code"
                  value={values?.code || ""}
                  onChange={onHandleChange}
                  className="input-style-costCenters"
                />
              </div>
              <div className="form-group-costCenters">
                <label htmlFor="area" className="form-label-costCenters">
                  Área:
                </label>
                <Input
                  placeholder="Área"
                  id="area"
                  name="area"
                  value={values?.area || ""}
                  onChange={onHandleChange}
                  className="input-style-costCenters"
                />
              </div>
              <div className="form-group-costCenters">
                <label htmlFor="project" className="form-label-costCenters">
                  Proyecto:
                </label>
                <Input
                  placeholder="Proyecto"
                  id="project"
                  name="project"
                  value={values?.project || ""}
                  onChange={onHandleChange}
                  className="input-style-costCenters"
                />
              </div>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default CostCentersDetail;
