import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row } from "antd";
import FormItem from "antd/lib/form/FormItem";
import AllSubcategoriesSelect from "../../Subcategories/UI/AllSubcategoriesSelect";
import EditableItem from "../../Subcategories/UI/EditableItem";

const CompaniesOffset = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {}, [items]);

  return (
    <Form>
      <Row>
        <Col xs={24} xl={12}>
          <FormItem label={"Supplier ID"} name={"supplier_id"}>
            <Input />
          </FormItem>
        </Col>
        <Col xs={24} xl={12}>
          <FormItem label={"Supplier Name"} name={"supplier_name"}>
            <Input />
          </FormItem>
        </Col>
        <AllSubcategoriesSelect setItems={setItems} />
        <div>
          <div style={{ marginTop: 10 }}>
            <h3>Items:</h3>
          </div>
          {items.map((item) => (
            <EditableItem key={item} id={item} />
          ))}
        </div>
      </Row>
    </Form>
  );
};

export default CompaniesOffset;
