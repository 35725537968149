import React, { useState, useEffect } from "react";
import { getProjectIpfs } from "../Application/Projects.business";
import { List } from "antd";
import { useSelector } from "react-redux";
import { getIpfsSelector } from "../Infrastructure/Projects.reducer";
import { FolderOutlined, FileTextOutlined } from "@ant-design/icons";

export default function ProjectIPFS({ record }) {
  const ipfs = useSelector(getIpfsSelector);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProjectIpfs(record.id).then((data) => {
      setLoading(false);
    });
  }, [record.id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <List
          bordered
          dataSource={ipfs?.resources}
          renderItem={(item, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  item.type === "folder" ? (
                    <FolderOutlined
                      style={{ fontSize: "16px", color: "#08c" }}
                    />
                  ) : (
                    <FileTextOutlined
                      style={{ fontSize: "16px", color: "#08c" }}
                    />
                  )
                }
                title={
                  <a target="_blank" href={item.url}>
                    {item.hash}
                  </a>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}
