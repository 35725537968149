import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  Tooltip,
  message,
  Modal as ModalAntd,
} from "antd";
import "./Owners.css";
import Formatter from "../../Data/Domain/Formatter";
import InterfaceTable from "../../Table/InterfaceTable";
import { DeleteOutlined } from "@ant-design/icons";
import DocumentUpload from "../../DocumentUpload/UI/DocumentUpload";
import Document from "../../DocumentUpload/Domain/Document";
import { getProjectImages } from "../../Projects/Application/Projects.business";
import { useSelector } from "react-redux";
import {
  getImagesSelector,
  getcountImagesSelector,
} from "../../Projects/Infrastructure/Projects.reducer";
import { deleteFileImage } from "../../Projects/Application/Projects.business";

const { Text } = Typography;

const Images = ({ recordId }) => {
  const imagesByProject = useSelector(getImagesSelector);
  // ;
  const countImages = useSelector(getcountImagesSelector);
  const [imageCounter, setImageCounter] = useState(0);

  useEffect(() => {
    getProjectImages();
    if (recordId) {
      getProjectImages(recordId);
    }
  }, [recordId, imageCounter]);

  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState("false");
  const handleDelete = async (recordId, id_image) => {
    try {
      ModalAntd.confirm({
        title: "¿Está seguro de que desea eliminar una imagen?",
        okText: "Si",
        okType: "danger",
        onOk: async () => {
          try {
            await deleteFileImage(recordId, id_image);
            message.success("Se eliminó la imagen");
          } catch (error) {
            message.error("Se produjo un error, vuelva a intentarlo");
          }
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const columns = [
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },

    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <>
            <div className="delete-ipfs">
              <div>
                <Tooltip title="Eliminar la carpeta">
                  <DeleteOutlined
                    onClick={() => {
                      handleDelete(recordId, record.id);
                    }}
                    style={{ color: "red" }}
                  />
                </Tooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  const fetchData = async (params = {}) => {
    try {
      await getProjectImages(recordId);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
      message.success("Filtrado");
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    getProjectImages(recordId);
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 5 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: [],
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  const handleImageUpdate = () => {
    setImageCounter(imageCounter + 1);
  };
  return (
    <div className="grid-owners">
      <div>
        <Card className="card-owners">
          <Row className="owners-title">
            <Col className="my-projects" span={24}>
              <Text className="text-my-projects">Imágenes del proyecto </Text>
              <div style={{ paddingLeft: "10px" }}>
                <DocumentUpload
                  type={Document.IMAGE_PROJECT}
                  id={recordId}
                  buttonText="Cargar"
                  updateImage={handleImageUpdate}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InterfaceTable
                data={imagesByProject.resources}
                columns={columns}
                onChange={handleRequest}
                pagination={{
                  pageSize: values.limit,
                  current: values.page + 1,
                  onChange: (current) => {
                    getProjectImages({
                      page: current - 1,
                      limit: values.limit,
                    });
                  },
                  total: countImages,
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default Images;
