import Response from "../../Data/Domain/Response";
import Contact from "../Domain/Contact";
import Project from "../Domain/Project";
import Ipfs from "../Domain/Ipfs";
import Image from "../Domain/Image";
import TransactionProject from "../Domain/TransactionProject";

import {
  getProjects,
  getProject,
  updateBonds,
  patchProject,
  updateProject,
  updateCoordinates,
  removeContact,
  getProjectIpfs,
  deleteFileIpfs,
  getProjectImages,
  deleteFileImage,
  getProjectTransactions,
} from "./Projects.service";

export async function getProjectsRepository(params) {
  const data = await getProjects(params);
  return new Response(data, Project).getMultiple();
}

export async function getProjectIpfsRepository(id) {
  const data = await getProjectIpfs(id);

  return new Response(data, Ipfs).getMultiple();
}

export async function getProjectRepository(id) {
  const data = await getProject(id);
  return new Response(data, Project).getSingle();
}

export async function updateBondsRepository(id, params) {
  const data = await updateBonds(id, params);
  return new Response(data, Project).onlyStatus();
}

export async function updateProjectRepository(id, params) {
  const data = await updateProject(id, params);
  return new Response(data, Project).onlyStatus();
}

export async function updateCoordinatesRepository(id, params) {
  const data = await updateCoordinates(id, params);
  return new Response(data, Project).onlyStatus();
}

export async function patchProjectRepository(id, params) {
  const data = await patchProject(id, params);
  return new Response(data, Project).onlyStatus();
}
// export async function removeContactRepository(id) {
//   const data = await removeContact(id);
//   return new Response(data).onlyStatus();
// }
export async function removeContactRepository(id, params) {
  const data = await removeContact(id, params);
  return new Response(data, Contact).onlyStatus();
}
export async function deleteFileIpfsRepository(id, params) {
  const data = await deleteFileIpfs(id, params);
  return new Response(data, Ipfs).onlyStatus();
}
export async function getProjectImagesRepository(id) {
  const data = await getProjectImages(id);

  return new Response(data, Image).getMultiple();
}
export async function deleteFileImageRepository(id, params) {
  const data = await deleteFileImage(id, params);
  return new Response(data, Image).onlyStatus();
}

export async function getProjectTransactionsRepository(id, state, type) {
  const data = await getProjectTransactions(id, state, type);

  return new Response(data, TransactionProject).getMultiple();
}
