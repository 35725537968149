import React, { useState, useEffect } from "react";
import { Card, Table, message } from "antd";
import { getCarbonFlux } from "../Infrastructure/Nfts.service";
import Formatter from "../../Data/Domain/Formatter";

const CarbonFluxTable = ({ id }) => {
  const [carbonFluxArray, setCarbonFluxArray] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCarbonFluxData = async () => {
      if (id) {
        setLoading(true);
        try {
          const response = await getCarbonFlux(id);
          setCarbonFluxArray(response.data.carbonflux);
        } catch (error) {
          message.error("Error al cargar los datos de CarbonFlux");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCarbonFluxData();
  }, [id]);

  const renderValue = (value) => {
    if (value === null || value === undefined) {
      return "N/A";
    }
    if (typeof value === "object") {
      return (
        <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
          {JSON.stringify(value, null, 2)}
        </pre>
      );
    }
    if (typeof value === "number") {
      return Formatter.formatNumberNoRounding(value);
    }
    return value;
  };

  const columns = [
    {
      title: "Date & Time",
      dataIndex: ["station_data_info", "datetime"],
      key: "station_data_info.datetime",
      render: renderValue,
    },
    {
      title: "Gross contribution (g)",
      dataIndex: "gross_contribution",
      key: "gross_contribution",
      render: renderValue,
    },
    {
      title: "CO\u2082 Flux (g/m\u00B2)",
      dataIndex: ["station_data_info", "co2_flux"],
      key: "station_data_info.co2_flux",
      render: renderValue,
    },
    {
      title: "Productive area (m\u00B2)",
      dataIndex: ["station_data_info", "co2_flux_g_m2"],
      key: "station_data_info.co2_flux_g_m2",
      render: renderValue,
    },
    {
      title: "Remainder (g)",
      dataIndex: "remainder",
      key: "remainder",
      render: renderValue,
    },
    {
      title: "Total CO\u2082 (g)",
      dataIndex: "total_co2",
      key: "total_co2",
      render: renderValue,
    },
  ];

  return (
    <Card
      title={`Información de CarbonFlux (ID: ${id})`}
      style={{ width: "95%" }}
    >
      <Table
        columns={columns}
        dataSource={carbonFluxArray}
        loading={loading}
        rowKey="_id"
        pagination={false}
        bordered
        scroll={{ x: "max-content" }}
      />
    </Card>
  );
};

export default CarbonFluxTable;
