import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Row, Table, Form, message, Space } from "antd";
import Formatter from "../../Data/Domain/Formatter";
import { Title } from "../../Utils/UI/Text";
import {
  getProjectsCountSelector,
  getProjectsSelector,
} from "../Infrastructure/Projects.reducer";
import { getProjects, getProject } from "../Application/Projects.business";
import ProjectDetail from "./ProjectDetail";
import ProjectDetails from "./ProjectDetails";
import ProjectUploadImage from "./ProjectUploadImage";
import ProjectLocation from "./ProjectLocation";
import ProjectIPFS from "./ProjectIPFS";
import Modal from "../../Modal/Modal";
import ProjectsTranslations from "./ProjectsTranslations";
import URL from "../../Data/Domain/URL";
import NewProject from "./NewProject";
import NewContact from "./NewContact";
import ProjectEdit from "./ProjectEdit";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";
import Storage from "../../Data/Domain/Storage";
import User from "../../Users/Domain/User";
import {
  EnvironmentOutlinedIcon,
  FileImageOutlinedIcon,
  PlusSquareOutlinedIcon,
  GlobalOutlinedIcon,
  ProfileOutlinedIcon,
  LinkOutlinedIcon,
  FileWordOutlinedIcon,
  ProjectOutlinedIcon,
  ContactsOutlinedIcon,
} from "../../Utils/UI/Text";
export default function ProjectsBoard({ data }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const project = useSelector(getProjectsSelector);
  const update_bonds = "update_bonds";
  const count = useSelector(getProjectsCountSelector);
  const [loading, setLoading] = useState(false);
  const [rol] = useState(new Storage("rol").getItem());
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [filters, setFilters] = useState("false");

  const [form] = Form.useForm();

  const goToProject = (projectId) => {
    navigate(`/projects/${projectId}`);
  };
  useEffect(() => {
    fetchData({ page: values.page, limit: values.limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);
  const fetchData = async (params = {}) => {
    try {
      setLoading(true);

      const finalParams = {
        limit: values.limit,
        page: values.page,
        ...params,
      };

      await getProjects(finalParams);
      setLoading(false);
    } catch (error) {
      message.error("Error al cargar los datos");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, newFilters = {}, sorter = {}) => {
    const { current = 1, pageSize = values.limit } = newPagination;

    const updatedValues = {
      page: current - 1,
      limit: pageSize,
    };
    setValues(updatedValues);

    const cleanParams = Formatter.cleanParamsFromTableToAPI({
      ...newFilters,
      limit: updatedValues.limit,
      page: updatedValues.page,
      dates: [],
    });

    setFilters(newFilters);
    fetchData(cleanParams);
  };
  useEffect(() => {
    fetchData({
      limit: values.limit,
      page: values.page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = {
    marginBottom: 20,
  };

  const flex = {
    display: "flex",
  };

  const isPublic = (record) => {
    return (
      (rol !== User.PROJECTS_VIEWER || rol !== User.PROJECTS_SELLER) && (
        <>
          <Space size="middle">
            {<ProjectEdit record={record} refreshData={fetchData} />}
            {
              <Modal
                children={<ProjectLocation record={record} />}
                icon={
                  <EnvironmentOutlinedIcon TooltipEnvironmentOutlined="Ubicación del Proyecto" />
                }
                title="Ubicación del Proyecto"
              />
            }

            {
              <Modal
                children={<ProjectUploadImage record={record} />}
                icon={
                  <FileImageOutlinedIcon TooltipFileImageOutlined="Imagen del Proyecto" />
                }
                title="Imagen del Proyecto"
              />
            }
            {
              <Modal
                children={
                  <ProjectDetail
                    record={record}
                    update_bonds={update_bonds}
                    form={form}
                  />
                }
                icon={
                  <PlusSquareOutlinedIcon TooltipPlusSquareOutlined="Detalle de Proyectos" />
                }
                title="Detalle de Proyectos"
                resetFields={update_bonds}
                form={form}
                cancelButtonProps={{ style: { display: "none" } }}
                okText="Cerrar"
              />
            }
            {
              <Modal
                icon={
                  <GlobalOutlinedIcon TooltipGlobalOutlined="Traducciones" />
                }
                title="Traducciones"
                width={1000}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
                children={<ProjectsTranslations record={record} />}
              />
            }
            {
              <a
                href={
                  record ? `${URL.EGREEN_NEW_PAGE}/project/${record.id}` : null
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <ProfileOutlinedIcon TooltipProfileOutlined="Descripción del Proyecto" />
              </a>
            }

            {
              <Modal
                width="600px"
                children={<ProjectIPFS record={record} />}
                icon={
                  <EnvironmentOutlinedIcon TooltipEnvironmentOutlined="Hashes in IPFS" />
                }
                title="Hashes in IPFS"
              />
            }
          </Space>
        </>
      )
    );
  };
  const isPrivate = (record) => {
    return (
      <>
        <Space size="middle">
          {rol !== User.PROJECTS_SELLER && rol !== User.PROJECTS_VIEWER && (
            <ProjectEdit record={record} refreshData={fetchData} />
          )}
          {
            <a
              href={record ? `${record.url}` : null}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkOutlinedIcon TooltipLinkOutlined="URL del Proyecto" />
            </a>
          }
          {
            <a
              href={record ? `${record.url_file}` : null}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FileWordOutlinedIcon TooltipFileWordOutlined="Documento del Proyecto" />
            </a>
          }

          {rol !== User.PROJECTS_SELLER && rol !== User.PROJECTS_VIEWER && (
            <Modal
              children={<ProjectLocation record={record} />}
              icon={
                <EnvironmentOutlinedIcon TooltipEnvironmentOutlined="Ubicación del Proyecto" />
              }
              title="Ubicación del Proyecto"
            />
          )}

          {rol !== User.PROJECTS_SELLER && rol !== User.PROJECTS_VIEWER && (
            <ProjectOutlinedIcon
              onClick={() => goToProject(record.id)}
              TooltipProjectOutlined="detalles del proyecto"
            />
          )}
        </Space>
      </>
    );
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "ID",
      dataIndex: "standard_id",
      key: "standard_id",
    },
    {
      title: "Tipo",
      dataIndex: "privateValue",
      key: "privateValue",
      filters: [
        {
          text: "Publico",
          value: false,
        },
        {
          text: "Privado",
          value: true,
        },
      ],
    },
    {
      title: "Add On",
      dataIndex: "add_on",
      key: "add_on",
    },
    {
      title: "Ubicación",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Tecnologia",
      dataIndex: "technology",
      key: "technology",
    },
    {
      title: "Vintage",
      dataIndex: "vintage",
      key: "vintage",
    },
    {
      title: "CO2",
      dataIndex: "availableBonds",
      key: "availableBonds",
    },
    {
      title: "Precio",
      dataIndex: "tonPrice",
      key: "tonPrice",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      onCell: (record) => {
        return {
          onClick: () => {
            getProject(record.id);
          },
        };
      },
      render: (record) => {
        return (
          <div style={flex}>
            {record.is_private && isPrivate(record)}{" "}
            {!record.is_private && isPublic(record)}{" "}
            <Space size="middle">
              {" "}
              {rol !== User.PROJECTS_SELLER && rol !== User.PROJECTS_VIEWER && (
                <Modal
                  children={<NewContact record={record} form={form} />}
                  icon={
                    <ContactsOutlinedIcon TooltipContactsOutlined="Nuevo Contacto" />
                  }
                  title="Nuevo Contacto"
                  resetFields={""}
                  form={form}
                  cancelButtonProps={{ style: { display: "none" } }}
                  okText="Cerrar"
                  width="auto"
                />
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  const columnsBasedOnRole = () => {
    const inner = columns.filter((element) => element.title !== "Precio");
    return rol === User.PROJECTS_SELLER ? inner : columns;
  };

  return (
    <>
      {id ? (
        <ProjectDetails />
      ) : (
        <main>
          <Row style={title}>
            <Col
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 20,
              }}
              span={24}
            >
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 20,
                }}
                span={12}
              >
                <Title>Proyectos</Title>
                <NewProject />
              </Col>
              <Col
                span={12}
                style={{
                  width: 112,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <ExportButtonElement
                  type={"projects"}
                  text={"Exportar"}
                  filter={filters}
                />
              </Col>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                rowKey="id"
                pagination={{
                  pageSize: values.limit,
                  total: count,
                  onChange: (current) => {
                    //getProjects({ page: current - 1, values.limit });
                  },
                }}
                onChange={handleRequest}
                dataSource={project}
                columns={columnsBasedOnRole()}
              />
            </Col>
          </Row>
        </main>
      )}
    </>
  );
}
