import { useEffect, useState } from "react";
import { Form, Row, message, Col } from "antd";
import { updateProject } from "../Application/Projects.business";
import {
  EditOutlined,
  LockOutlined,
  GlobalOutlined,
  CloudOutlined,
  DollarCircleOutlined,
  LinkOutlined,
  PlusOutlined,
  CodeOutlined,
  CalendarOutlined,
  BorderOutlined,
  EnvironmentOutlined,
  BarChartOutlined,
  BranchesOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { EditIcon } from "../../Utils/UI/Text";
import Modal from "../../Modal/Modal";
import { CustomFormItem, CustomTFormItenextArea } from "../../Utils/UI/Text";
import CustomError from "../../Utils/Domain/CustomError";

export default function ProjectEdit({ record, refreshData }) {
  const [values, setValues] = useState(record);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setValues(record);
  }, [record]);

  const handleUpdate = async () => {
    const data = {
      project_name: values.project_name,
      is_private: values.is_private,
      country: values.country,
      co2Goal: values.co2Goal,
      ton_price: values.ton_price,
      add_on: values.add_on,
      technology: values.technology,
      vintage: values.vintage,
      url: values.url,
      url_file: values.url_file,
      area: values.area,
      biomass: values.biomass,
      co2_annual_estimated: values.co2_annual_estimated,
      contract_start_date: values.contract_start_date,
      contract_end_date: values.contract_end_date,
      description: values.description,
      green_project_type: values.green_project_type,
      region: values.region,
      volume: values.volume,
      ton_co2_issued: values.ton_co2_issued,
      province: values.province,
    };

    try {
      setLoading(true);
      await updateProject(record.id, data);
      message.success("Proyecto actualizado con éxito");
      setLoading(false);
      refreshData();
      updateProject(record.id, data);
      return true;
    } catch (error) {
      const customError = new CustomError(error);

      message.error(customError.message);

      return false;
    }
  };

  return (
    <Modal
      children={
        <Form
          name="basic"
          autoComplete="off"
          layout="vertical"
          responsive={"true"}
          initialValues={values}
          onFinish={handleUpdate}
          onValuesChange={(_, allValues) => setValues(allValues)}
        >
          {" "}
          {record ? (
            <>
              <Row gutter={8}>
                <CustomTFormItenextArea
                  label="Nombre"
                  name="project_name"
                  placeholder="Ingrese el nombre del proyecto"
                  iconSuffix={<EditOutlined style={{ color: "#1890ff" }} />}
                  maxLength={50}
                  rows={1}
                />
              </Row>

              <Row gutter={8}>
                <CustomFormItem
                  label="Tipo"
                  name="is_private"
                  disabled
                  iconSuffix={<LockOutlined style={{ color: "#faad14" }} />}
                />
              </Row>

              <Row gutter={8}>
                <CustomFormItem
                  label="Ubicación"
                  name="country"
                  placeholder="Ingrese la ubicación"
                  iconSuffix={<GlobalOutlined style={{ color: "#52c41a" }} />}
                />
              </Row>

              <Row gutter={8}>
                <Col span={12}>
                  <CustomFormItem
                    label="CO2"
                    name="co2Goal"
                    placeholder="Ingrese el objetivo de CO2"
                    iconSuffix={<CloudOutlined style={{ color: "#52c41a" }} />}
                  />
                </Col>

                <Col span={12}>
                  <CustomFormItem
                    label="Precio"
                    name="ton_price"
                    placeholder="Ingrese el precio"
                    iconSuffix={
                      <DollarCircleOutlined style={{ color: "#fa541c" }} />
                    }
                  />
                </Col>
              </Row>

              {values.is_private && (
                <>
                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Add On"
                        name="add_on"
                        placeholder="Ingrese el Add On"
                        iconSuffix={
                          <PlusOutlined style={{ color: "#1890ff" }} />
                        }
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Tecnología"
                        name="technology"
                        placeholder="Ingrese la tecnología"
                        iconSuffix={
                          <CodeOutlined style={{ color: "#52c41a" }} />
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Vintage"
                        name="vintage"
                        placeholder="Ingrese el vintage"
                        iconSuffix={
                          <CalendarOutlined style={{ color: "#faad14" }} />
                        }
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="URL"
                        name="url"
                        placeholder="Ingrese la URL"
                        iconSuffix={
                          <LinkOutlined style={{ color: "#fa541c" }} />
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <CustomFormItem
                      label="URL"
                      name="url"
                      placeholder="Ingrese la URL"
                      iconSuffix={<LinkOutlined style={{ color: "#fa541c" }} />}
                    />
                  </Row>
                </>
              )}
              {!values.is_private && (
                <>
                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Área"
                        name="area"
                        placeholder="Ingrese el área"
                        iconSuffix={
                          <BorderOutlined style={{ color: "#52c41a" }} />
                        }
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Biomasa"
                        name="biomass"
                        placeholder="Ingrese la biomasa"
                        iconSuffix={
                          <BranchesOutlined style={{ color: "#389e0d" }} />
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="CO2 estimado anual"
                        name="co2_annual_estimated"
                        placeholder="Ingrese el CO2 estimado"
                        iconSuffix={
                          <CloudOutlined style={{ color: "#fa541c" }} />
                        }
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Fecha inicial del contrato"
                        name="contract_start_date"
                        placeholder="Ingrese la fecha de inicio"
                        iconSuffix={
                          <CalendarOutlined style={{ color: "#1890ff" }} />
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Fecha de terminación del contrato"
                        name="contract_end_date"
                        placeholder="Ingrese la fecha de finalización"
                        iconSuffix={
                          <CalendarOutlined style={{ color: "#faad14" }} />
                        }
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Descripción"
                        name="description"
                        placeholder="Ingrese la descripción"
                        iconSuffix={
                          <FileTextOutlined style={{ color: "#595959" }} />
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Tipo de proyecto"
                        name="green_project_type"
                        placeholder="Ingrese el tipo de proyecto"
                        iconSuffix={
                          <EnvironmentOutlined style={{ color: "#52c41a" }} />
                        }
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Región"
                        name="region"
                        placeholder="Ingrese la región"
                        iconSuffix={
                          <GlobalOutlined style={{ color: "#13c2c2" }} />
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Volumen"
                        name="volume"
                        placeholder="Ingrese el volumen"
                        iconSuffix={
                          <BarChartOutlined style={{ color: "#9254de" }} />
                        }
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="CO2 emitido"
                        name="ton_co2_issued"
                        placeholder="Ingrese el CO2 emitido"
                        iconSuffix={
                          <CloudOutlined style={{ color: "#ff4d4f" }} />
                        }
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Provincia"
                        name="province"
                        placeholder="Ingrese la provincia"
                        iconSuffix={
                          <EnvironmentOutlined style={{ color: "#52c41a" }} />
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            ""
          )}
        </Form>
      }
      icon={<EditIcon TooltipEdit="Editar un  proyecto" />}
      title="Editar Proyecto"
      cancelButtonProps={{ style: { display: "none" } }}
      text="Editar Proyecto"
      handleSubmit={handleUpdate}
      PlusCircleOutlined={<EditOutlined />}
      form={form}
    />
  );
}
