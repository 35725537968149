import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getTechnology(params) {
  const { id, technology, start_date, finish_date } = params;

  const response = await new API(URL.buildUrl(`${technology}/${id}`)).post({
    start_date,
    finish_date,
  });

  return await new API(URL.buildUrl(`${technology}/${id}`)).get();
}

export async function deleteTechnology(params) {
  const { id, tecnologia } = params;
  return await new API(URL.buildUrl(`${tecnologia}/${id}`)).delete();
}

export async function deleteAllTechnology(params) {
  const { id, technology } = params;
  return await new API(URL.buildUrl(`/users/${id}/${technology}`)).delete();
}
