import Response from "../../Data/Domain/Response";
import ScriptOffset from "../Domain/ScriptOffsets";
import {
  getScriptOffsetByUser,
  updateScriptOffset,
} from "./ScriptOffsets.service";

export async function getScriptOffsetByUserRepository(id, params) {
  try {
    const data = await getScriptOffsetByUser(id, params);
    return new Response(data, ScriptOffset).getMultiple();
  } catch (error) {
    throw error;
  }
}

export async function updateScriptOffsetRepository(id, params) {
  try {
    const data = await updateScriptOffset(id, params);
    return new Response(data, ScriptOffset).onlyStatus();
  } catch (error) {
    throw error;
  }
}
