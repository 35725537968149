import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Input,
  message,
  Col,
  Row,
  Typography,
  Button,
  Checkbox,
  DatePicker,
  Select,
} from "antd";
import {
  CalendarOutlined,
  TagOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import UserReferenceField from "../Users/UI/UserReferenceField";
import { updateSaving } from "../Saving/Infrastructure/Saving.service";
import { getReductionById } from "../Saving/Application/Saving.business";
import { getSelectedReductionSelector } from "../Saving/Infrastructure/Saving.reducer";
import { getsetCollaboratorsSelector } from "../Collaborators/Infrastructure/Collaborators.reducer";
import { getByCompany } from "../Collaborators/Application/Collaborators.business";
import { removeCollaborator } from "../Saving/Infrastructure/Saving.service";

import "./Assignation.css";
import UserBadge from "../UserBadge/UserBadge";

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const styles = {
  icon: {
    marginRight: 12,
    color: "#8FA0B6",
    height: "11.6px",
    width: "15.8px",
    borderRadius: "0px",
  },
  iconCalendar: {
    color: "#8FA0B6",
    height: "11.6px",
    width: "15.8px",
    borderRadius: "0px",
  },
  text: {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#041370",
  },
  checklist: {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#041370",
    opacity: 0.5,
  },

  checkboxGroup: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  body: {
    marginTop: 15,
    paddingLeft: "1em",
  },
  firstFile: {
    display: "flex",
    marginBottom: 8,
  },
  secondFile: {
    display: "flex",
    alignItems: "center",
  },
  thirdFile: {
    display: "flex",
    marginTop: 10,
  },
  containerChecklist: {
    marginTop: 15,
    marginBottom: 8,
  },
  collaboratorText: {
    fontFamily: "MontserratRegular",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#041370",
    width: "100%",
  },
};

const Assignation = ({ id }) => {
  const reduction = useSelector(getSelectedReductionSelector);
  const collaborators = useSelector(getsetCollaboratorsSelector);
  const [items, setItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [name, setName] = useState("");
  const [text, setText] = useState("");
  const [assigned, setAssigned] = useState([]);
  const inputRef = useRef(null);
  // Fechas
  const [initialDate, setInitialDate] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const dates = useRef({
    initial_date: initialDate,
    final_date: finalDate,
  });
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    getReductionById(id);
    getByCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reduction) {
      setText(reduction.name);
      setInitialDate(reduction.initial_date);
      setFinalDate(reduction.final_date);
      setCleanItems(reduction.items);
      dates.current = {
        initial_date: reduction.initial_date,
        final_date: reduction.final_date,
      };
      setAssigned(reduction.assigned);
    }
  }, [reduction, assigned]);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSelectChange = async (value) => {
    const data = {
      assigned: [value],
    };
    try {
      await updateSaving(id, data);
      getReductionById(id);
      setSelectedValue(null);
    } catch (error) {
      message.error(
        "Se produjo un error agregando una nueva tarea, vuelve a intentarlo"
      );
    }
  };

  const addItem = async (e) => {
    e.preventDefault();
    const data = {
      items: [
        {
          task: name,
          checked: false,
        },
      ],
    };
    try {
      await updateSaving(id, data);
      getReductionById(id);
      setName("");
      inputRef.current?.focus();
      message.success("Se agregó una nuevo item");
    } catch (error) {
      message.error(
        "Se produjo un error agregando una nueva tarea, vuelve a intentarlo"
      );
    }
  };

  const updateList = async (checkedValues) => {
    try {
      const items = transformItemsToUpdate(checkedValues);
      await updateSaving(id, { items }, "?full_items_update=true");
      message.success("Se ha actualizado la lista");
    } catch (error) {
      message.error(
        "Se produjo un error actualizando la lista, vuelve a intentarlo"
      );
    }
  };

  const setCleanItems = (inner_items) => {
    const checked_items = inner_items
      .filter((item) => item.checked)
      .map((item) => item._id);

    const clean_items = inner_items.map((item) => ({
      label: item.task,
      value: item._id,
    }));

    setItems(clean_items);
    setCheckedItems(checked_items);
  };

  const transformItemsToUpdate = (checkedValues) => {
    const new_items = items.map((item) => {
      const checked = checkedValues.includes(item.value);
      return { ...item, task: item.label, _id: item.value, checked };
    });
    return new_items;
  };

  const onChange = (checkedValues) => {
    setCheckedItems(checkedValues);
    updateList(checkedValues);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };

  const addDate = async (e) => {
    try {
      await updateSaving(id, dates.current);
      message.success("Se agregó una nueva fecha");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
  };

  const onChangeDate = (changed_values) => {
    let [initial, final] = changed_values;
    initial = initial ? moment(initial).format("YYYY-MM-DD") : initial;
    final = final ? moment(final).format("YYYY-MM-DD") : final;
    dates.current = {
      initial_date: initial,
      final_date: final,
    };
    addDate({}, {});
  };
  const startDate = moment(reduction ? reduction.initial_date : "").format(
    "YYYY-MM-DD"
  );
  const endDate = moment(reduction ? reduction.final_date : "").format(
    "YYYY-MM-DD"
  );

  const handleDelete = async (id, id_collaborator) => {
    try {
      await removeCollaborator(id, id_collaborator);
      getReductionById(id);
      message.success("Se ha eliminado el colaborador de la lista");
    } catch (error) {
      message.error(
        "Se produjo un error agregando una nueva tarea, vuelve a intentarlo"
      );
    }
  };

  return (
    <div>
      <div style={styles.header}>
        <div>
          <UserReferenceField
            dashboard={true}
            occupation="Dev"
            stylesUsername="usernameAssignation"
            container="containerAssignation"
            containerText="containerTextAssignation"
            containerRight="containerRightAssignation"
            occupationStyle="occupationAssignation"
            size={50}
            dropdown={<></>}
          />
        </div>
        <div>{/*<Button className="assignation">. . .</Button>*/}</div>
      </div>
      <div style={styles.body}>
        <Row>
          <Col span={24} style={styles.firstFile}>
            <TagOutlined style={styles.icon} />
            <Text style={styles.text}>{text}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={styles.secondFile}>
            <CalendarOutlined style={styles.iconCalendar} />
            <RangePicker
              disabledDate={disabledDate}
              suffixIcon={null}
              placeholder={[
                "" ? "Start Date" : startDate,
                "" ? "End Date" : endDate,
              ]}
              bordered={false}
              onChange={onChangeDate}
              // defaultValue={[startDate,endDate]}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={styles.thirdFile}>
            <UsergroupAddOutlined style={styles.icon} />
            <Text style={styles.text}>Assigned</Text>
          </Col>
        </Row>

        <Row gutter={2} style={styles.containerChecklist}>
          <Col span={14}>
            <Select
              showSearch
              //onSearch={handleSearch}
              style={styles.collaboratorText}
              placeholder="Selecciona un usuario"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={selectedValue}
              onChange={handleSelectChange}
            >
              {collaborators?.map((collaborator) => (
                <Option
                  key={collaborator.id}
                  value={collaborator.id}
                  style={styles.collaboratorText}
                >
                  {collaborator.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        {assigned?.map((elem) => (
          <UserBadge
            email={elem.email}
            handleDelete={() => handleDelete(id, elem._id)}
          />
        ))}

        <Row gutter={2} style={styles.containerChecklist}>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
            span={24}
          >
            <Text style={styles.checklist}>Checklist</Text>
          </Col>
        </Row>
        <Checkbox.Group
          style={styles.checkboxGroup}
          options={items}
          onChange={onChange}
          value={checkedItems}
        />
        <Row gutter={2} style={styles.containerChecklist}>
          <Col span={13}>
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              className={"add-checkbox"}
            />
          </Col>
          <Col span={11}>
            <Button
              className={"add-checkbox"}
              type="text"
              icon={<PlusOutlined />}
              onClick={addItem}
            >
              Agregar tarea
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Assignation;
