import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getStationData(params) {
  const url = URL.setParams(URL.buildUrl("stationdata"), params);

  try {
    const response = await new API(url).get();

    return response;
  } catch (error) {
    throw error;
  }
}
