import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getLogs(params) {
  const url = URL.setParams(URL.buildUrl("logs"), params);

  try {
    const response = await new API(url).get();

    return response;
  } catch (error) {
    throw error;
  }
}
export async function getLog(id) {
  return await new API(URL.setId(URL.buildUrl("logs"), id)).get();
}
export async function postGenerateHash(id, hash) {
  return await new API(URL.buildUrl(`logs/${id}/hash`)).post(hash);
}
