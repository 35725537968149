import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Categories from "../../Layout/Categories/Categories";
import { Row, Col, Typography, message, Spin } from "antd";
import ScopeBar from "../../Layout/Scopes/Scopes";
import { getCategories } from "../../Categories/Application/Categories.business";
import { getCategoriesSelector } from "../../Categories/Infrastructure/Categories.reducer";
import Switch from "../../Switch/Switch";
import { getOffsets } from "../../DashboardOffset/Application/DashboardOffset.business";
import { getOffsetsSelector } from "../../DashboardOffset/Infrastructure/DashboardOffset.reducer";
import Offset from "../../DashboardOffset/Domain/DashboardOffset";
import { getSelectedOffsetYearSelector } from "../../YearsUser/Infrastructure/YearsUser.reducer";

const { Text } = Typography;

const titleContainer = {
  marginTop: "42px",
  marginBottom: "20px",
  fontFamily: "MontserratMedium",
  fontWeight: 500,
  fontSize: "23px",
};

const loadingStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 999,
};
const spinnerContainerStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const Scopes = () => {
  const record = useSelector(getCategoriesSelector);
  const [scope, setScope] = useState("Scope 1");
  const [loading, setLoading] = useState(true);
  const [stream, setStream] = useState("upstream");
  const emissions = useSelector(getOffsetsSelector);
  const [data, setData] = useState([]);
  const offsetYear = useSelector(getSelectedOffsetYearSelector);

  const fetchData = async (params = {}) => {
    try {
      await getCategories(params);
      setLoading(false);
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    getOffsets();
  }, []);

  useEffect(() => {
    const lowerScope = scope.toLowerCase();
    let filter = { scope: lowerScope };
    if (lowerScope === "scope 3") {
      filter = { scope: lowerScope, [`${stream}`]: true };
    }
    fetchData({ filter });
  }, [scope]);

  useEffect(() => {
    if (offsetYear) {
      setLoading(true);

      fetchData().then((r) => {
        setLoading(false);
        console.log(
          "Dashboard data fetched in useEffect with OffsetYear dependency."
        );
      });
    } else {
    }
  }, [offsetYear]);

  useEffect(() => {
    if (emissions.scopes !== undefined) {
      let scopes = emissions.scopes;
      let emissionsData = [];
      let index = 0;
      for (let key in scopes) {
        if (scopes[key].quantity !== undefined) {
          let obj = {};
          obj.id = ++index;
          obj.title = Offset.formatScopes(key);
          obj.subtitle = `${scopes[key].quantity} tons CO₂`;
          obj.percent = scopes[key].percent || 0;
          emissionsData.push(obj);
        }
      }
      setData(emissionsData);
    }
  }, [emissions]);

  useEffect(() => {
    if (data.length > 0) {
    }
  }, [data]);

  const onChange = ({ target: { value } }) => {
    setLoading(true);
    setScope(value);
  };

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {loading && (
        <div style={loadingStyle}>
          <Spin
            spinning={loading}
            style={spinnerContainerStyle}
            tip="Loading"
            size="large"
          ></Spin>
        </div>
      )}

      <div>
        {data && (
          <ScopeBar
            key={data.id}
            data={data}
            onChange={onChange}
            active={scope}
          />
        )}
        {scope && record ? (
          <Row>
            <Col span={24} style={titleContainer}>
              <Text style={{ color: "#041370" }}>
                <span style={{ fontFamily: "MontserratBold" }}>Categories</span>
                {` ${Offset.formatScopes(scope)}`}
                {scope.includes("3") && (
                  <Switch
                    switchLeft={"upstream"}
                    switchRight={"downstream"}
                    setStream={setStream}
                  />
                )}
              </Text>
            </Col>
            <Col span={24}>
              {/* <Spin spinning={loading}> */}
              <Categories stream={stream} />
              {/* </Spin> */}
            </Col>
          </Row>
        ) : (
          <div />
        )}
        <p>
          {offsetYear
            ? `año seleccionado: ${offsetYear}`
            : "seleccionar un año"}
        </p>
      </div>
    </div>
  );
};

export default Scopes;
