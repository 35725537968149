import { createSlice } from "@reduxjs/toolkit";
import { getCarbonFlux } from "../Application/CarbonFlux.business";

export const slice = createSlice({
  name: "carbonFlux",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setCarbonFlux: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countCarbonFlux: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
  },
});

export const { setCarbonFlux, countCarbonFlux } = slice.actions;
export default slice.reducer;

export const getCarbonFluxSelector = (store) => store.carbonFlux.list;
export const getCarbonFluxCountSelector = (store) => store.carbonFlux.count;

export const fetchCarbonFlux = (params) => async (dispatch) => {
  try {
    const response = await getCarbonFlux(params);
    dispatch(setCarbonFlux(response.data.resources));
    dispatch(countCarbonFlux(response.data.count));
  } catch (error) {}
};
