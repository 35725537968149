import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Spin } from "antd";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import InfoCard from "../../Layout/DesignSystem/InfoCard/InfoCard";
import EmissionCategory from "../../EmissionCategory/UI/EmissionCategory";
import "./DashboardOffset.css";
import DonutChart from "../../Charts/DonutChart/DonutChart";
import BarChart from "../../Charts/BarChart/BarChart";
import LineChart from "../../Charts/LineChart/LineChart";
import { getOffsets } from "../Application/DashboardOffset.business";
import { getSuppliersByUser } from "../../Suppliers/Application/Suppliers.business";
import { getOffsetsSelector } from "../Infrastructure/DashboardOffset.reducer";
import { getSuppliersByUserSelector } from "../../DataUser/Infrastructure/DataUser.reducer";
import { getAreasByUser } from "../../Areas/Application/Areas.business";
import { getAreasByUserSelector } from "../../DataUser/Infrastructure/DataUser.reducer";
import { getRepositoryProjectsByUserSelector } from "../../DataUser/Infrastructure/DataUser.reducer";
import { getRepositoryProjectByUser } from "../../RepositoryProjects/Application/RepositoryProjects.business";
import Formatter from "../../Data/Domain/Formatter";
import Emissions from "./Emissions";
import { getSelectedOffsetYearSelector } from "../../YearsUser/Infrastructure/YearsUser.reducer";

const styles = {
  container: {
    marginBottom: 20,
    marginTop: 30,
  },
  containerTons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  ton: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#041370",
  },
  tons: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "37px",
    color: "#041370",
  },
  span: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "37px",
    color: "#041370",
  },
  scope: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "15px",
    color: "#00A389",
  },
  down: {
    color: "#00A389",
    fontSize: 15,
    fontWeight: "bold",
  },
  up: {
    background: "#ffd9d9",
    color: "#FF1E46",
    fontSize: 15,
    fontWeight: "bold",
  },
  migrations: {
    fontSize: 15,
    color: "#00A389",
    fontWeight: 600,
  },
  loadingStyle: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999,
  },
  spinnerContainerStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const DashboardOffset = () => {
  const emissions = useSelector(getOffsetsSelector);
  const supplierData = useSelector(getSuppliersByUserSelector);
  const areaData = useSelector(getAreasByUserSelector);
  const repositoryProjectsData = useSelector(
    getRepositoryProjectsByUserSelector
  );
  const [areas, setAreas] = useState();
  const [suppliers, setSuppliers] = useState();
  const [repositoryProjects, setRepositoryProjects] = useState();
  const [cards, setCards] = useState({});
  const [year, setYear] = useState("");
  const offsetYear = useSelector(getSelectedOffsetYearSelector);
  const [loading, setLoading] = useState(true);

  const fetchDashboardData = async () => {
    try {
      await getOffsets();
      await getSuppliersByUser();
      await getAreasByUser();
      await getRepositoryProjectByUser();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData().then((r) => {});
  }, []);

  useEffect(() => {
    if (offsetYear && year) {
      if (
        (offsetYear !== year && year !== "") ||
        Object.keys(emissions).length === 0
      ) {
        setLoading(true);
        fetchDashboardData().then((r) => {
          console.log(
            "Dashboard data fetched in useEffect with OffsetYear dependency."
          );
        });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [offsetYear, year, emissions]);

  useEffect(() => {
    if (emissions.data !== undefined) {
      const data = emissions.data;
      let emissionsData, yearData;

      for (const year in data) {
        yearData = year;
        emissionsData = data[year];
      }

      setCards(emissionsData);
      setYear(yearData);
    }
  }, [emissions]);

  const processArray = (data) => {
    let temp = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      element.arrowUp = element.up ? (
        <ArrowUpOutlined style={styles.up} />
      ) : null;
      element.arrowDown = element.down ? (
        <ArrowDownOutlined style={styles.down} />
      ) : null;
      element.iconMitigations = (
        <PlusCircleOutlined style={styles.migrations} />
      );
      temp.push(element);
    }

    return temp;
  };

  useEffect(() => {
    setAreas(processArray(areaData));
    setSuppliers(processArray(supplierData));
    setRepositoryProjects(processArray(repositoryProjectsData));
  }, [areaData, supplierData, repositoryProjectsData]);

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {loading && (
        <div style={styles.loadingStyle}>
          <Spin
            spinning={loading}
            style={styles.spinnerContainerStyle}
            tip="Loading"
            size="large"
          ></Spin>
        </div>
      )}

      <Row
        style={{
          marginTop: 10,
        }}
        gutter={5}
      >
        <Col type="flex" align="middle" key={1} xs={24} sm={24} md={12} lg={6}>
          <InfoCard
            statistic={{
              value: Formatter.formatNumber(cards.total_co2),
              title: `Total Emission ${year}`,
              unit: "ton CO₂",
              type: "blue-card ",
            }}
          />
        </Col>

        <Col type="flex" align="middle" key={2} xs={24} sm={24} md={12} lg={6}>
          <InfoCard
            statistic={{
              value: Formatter.formatNumber(cards.emission_projected),
              title: `Emission projected ${+year + 1}`,
              unit: "ton CO₂",
              type: "dark-green-card ",
            }}
          />
        </Col>

        <Col type="flex" align="middle" key={3} xs={24} sm={24} md={12} lg={6}>
          <InfoCard
            statistic={{
              value: Formatter.formatNumber(cards.last_month_emission || 0.0),
              title: "Last month's emissions",
              unit: "ton CO₂",
              type: "dark-degraded-card",
            }}
          />
        </Col>

        <Col type="flex" align="middle" key={4} xs={24} sm={24} md={12} lg={6}>
          <InfoCard
            statistic={{
              value: Formatter.formatNumber(cards.emission_saving),
              title: "Reductions",
              unit: "ton CO₂",
              type: "green-card ",
            }}
          />
        </Col>
      </Row>

      <div className="grid-container">
        <div className="grid-item">
          <LineChart />
        </div>
        <div>
          <DonutChart />
        </div>
        <div className="grid-item">
          <BarChart />
        </div>
        <div className="grid-item">
          <EmissionCategory />
        </div>
      </div>
      {areas && (
        <Emissions
          title={"CO₂ emission per Business Area (ton CO₂)"}
          data={areas}
        />
      )}
      {suppliers && (
        <Emissions
          title={"CO₂ emission per Supplier (ton CO₂)"}
          data={suppliers}
        />
      )}
      {repositoryProjects && (
        <Emissions
          title={"CO₂ emission per Projects (ton CO₂)"}
          data={repositoryProjects}
        />
      )}
      <p>
        {offsetYear ? `año seleccionado: ${offsetYear}` : "seleccionar un año"}
      </p>
    </div>
  );
};

export default DashboardOffset;
