import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getScriptOffsetByUser(userId, params) {
  const url = URL.setParams(
    URL.buildUrl(`users/${userId}/scriptOffsets`),
    params
  );

  try {
    const response = await new API(url).get();

    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateScriptOffset(id, data) {
  const url = URL.setId(URL.buildUrl("scriptOffsets"), id);

  try {
    const response = await new API(url).put(data);
    return response.data;
  } catch (error) {
    throw error;
  }
}
