import { createSlice } from "@reduxjs/toolkit";
import { getScriptOffsetByUser } from "../Application/ScriptOffsets.business";

export const slice = createSlice({
  name: "scriptOffsets",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setScriptOffset: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countScriptOffset: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
  },
});

export const { setScriptOffset, countScriptOffset } = slice.actions;

export default slice.reducer;

export const getScriptOffsetSelector = (store) => store.scriptOffsets.list;
export const getScriptOffsetCountSelector = (store) =>
  store.scriptOffsets.count;

export const fetchScriptOffsets = (userId, params) => async (dispatch) => {
  try {
    const response = await getScriptOffsetByUser(userId, params);
    dispatch(setScriptOffset(response.data.resources));
    dispatch(countScriptOffset(response.data.count));
  } catch (error) {}
};
