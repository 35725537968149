import { useState } from "react";
import Modal from "../../Modal/Modal";
import { postGenerateHash } from "../Infrastructure/Logs.service";
import { message } from "antd";
import { LockOutlinedIcon } from "../../Utils/UI/Text";
import "./Logs.css";
import { CopyOutlined } from "@ant-design/icons";

const HashCreation = ({ record, fetchData }) => {
  const [loading, setLoading] = useState(false);
  const [hashGenerated, setHashGenerated] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await postGenerateHash(record.id, {});
      setHashGenerated(response.data.hash);
      message.success("Hash seguro generado con éxito");
      fetchData();
    } catch (error) {
      message.error("Se produjo un error al generar el hash");
    } finally {
      setLoading(false);
    }
  };

  const handleCopyHash = () => {
    if (!hashGenerated) return;
    navigator.clipboard
      .writeText(hashGenerated)
      .then(() => message.success("Hash copiado al portapapeles"))
      .catch(() => message.error("Error al copiar el hash"));
  };

  return (
    <div>
      <Modal
        children={<p>{hashGenerated ? hashGenerated : "Generando..."}</p>}
        icon={
          <LockOutlinedIcon
            TooltipLockOutlined="Crear un Hash"
            onClick={handleSubmit}
          />
        }
        title="Hash generado "
        cancelButtonProps={{ style: { display: "none" } }}
        text="Copiar"
        handleSubmit={hashGenerated ? handleCopyHash : undefined}
        PlusCircleOutlined={<CopyOutlined />}
        disableOnCondition={!hashGenerated}
      />
    </div>
  );
};

export default HashCreation;
