import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ProgressBar from "../ProgressBar/ProgressBar";
import { Col, Row, Space, Typography, Divider } from "antd";
import "./ExpandibleData.css";
import { getSubcategoriesBySuppliersAndUser } from "../Suppliers/Application/Suppliers.business";
import { getSuppliersBySubcategorySelector } from "../Suppliers/Infrastructure/Suppliers.reducer";

const { Text } = Typography;

const styles = {
  container: {},
  containerQuantity: {
    width: "50%",
  },
  containerName: {
    display: "flex",
    flexDirection: "column",
  },
  containerNameRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  quantity: {
    fontFamily: "MontserratMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "11px",
    lineHeight: "150%",
    letterSpacing: "0.02em",
    color: "#ffffff",
  },
  name: {
    fontFamily: "MontserratMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#00DD9E",
  },
  item: {
    fontFamily: "MontserratMedium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#ffffff",
  },

  containerArrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dividerItem: {
    color: "#da0808",
    background: "#ffffff",
    lineHeight: "2px",
    height: "1px",
  },
};

const emissions = [
  {
    name: "VTR",
    progress: 30,
    quantity: "200 Kilos",
  },
  {
    name: "MOVISTAR",
    progress: 30,
    percent: 30,
    quantity: "200 Kilos",
  },
  {
    name: "ENTEL",
    progress: 30,
    quantity: "200 Kilos",
  },
];

const ExpandibleData = (props) => {
  const { id_subcategory } = props;
  const expandibleSuppliers = useSelector(getSuppliersBySubcategorySelector);
  useEffect(() => {
    if (id_subcategory) {
      getSubcategoriesBySuppliersAndUser(id_subcategory);
    }
  }, [id_subcategory]);

  return (
    <div className="blue-containter">
      <Row className="blue-header" gutter={8}>
        <Col style={styles.containerArrow} xs={3} sm={1} md={3} lg={4}>
          <Space direction="vertical" size="large" class="space-dash">
            {props.arrowUp}
          </Space>
          <Space direction="vertical" size="large" class="space-below">
            {props.arrowDown}
          </Space>
        </Col>

        <Col xs={21} md={21} lg={20}>
          {expandibleSuppliers.map((emission) => (
            <>
              <Row className="blue-item" gutter={8}>
                <Col style={styles.containerName2} xs={12} sm={9} md={7} lg={8}>
                  <Text style={styles.item}>{emission.id}</Text>
                </Col>
                <Col style={styles.quantity} xs={12} sm={6} md={11} lg={8}>
                  <Row>
                    <Text style={styles.quantity}>
                      {emission.percent}% tons CO2
                    </Text>
                  </Row>
                  <Row>
                    <ProgressBar
                      percentSecond={emission.percent}
                      percent={emission.progress}
                    />
                  </Row>
                </Col>
                <Col
                  style={styles.containerNameRight}
                  xs={24}
                  sm={9}
                  md={7}
                  lg={4}
                >
                  <Text style={styles.item}>{emission.total}</Text>
                </Col>
                <Col xs={24} sm={24} md={20} lg={20}>
                  <Divider orientation="center" style={styles.dividerItem} />
                </Col>
              </Row>
            </>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default ExpandibleData;
