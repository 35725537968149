import React, { useState } from "react";
import { message, Form } from "antd";
import { getShopifyDistances } from "../Application/ShopifyDistances.business";
import { postShopifyDistances } from "../Infrastructure/ShopifyDistances.service";
import Modal from "../../Modal/Modal";
import { AddIcon, CustomFormItem } from "../../Utils/UI/Text";
import { PlusCircleOutlined } from "@ant-design/icons";
import CustomError from "../../Utils/Domain/CustomError";

const NewShopifyDistances = () => {
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    municipality: "",
    distance: "",
  });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = async () => {
    setLoading(true);

    const { municipality, distance } = values;

    try {
      await postShopifyDistances({ municipality, distance: Number(distance) });

      message.success("Se agregó una nueva distancia");
      setValues({ municipality: "", distance: "" });
      form.resetFields();
      getShopifyDistances();
      return true;
    } catch (error) {
      const customError = new CustomError(error);

      message.error(customError.message);

      if (customError.message.includes("")) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          municipality: customError.message,
        }));
      }
      return false;
    }
  };

  return (
    <div>
      <Modal
        children={
          <div>
            <Form
              className="horizontal-form-shopifyDistances"
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              onValuesChange={(changedValues, allValues) => {
                const updatedValues = { ...allValues };

                if (changedValues.municipality) {
                  updatedValues.municipality =
                    changedValues.municipality.replace(
                      /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                      ""
                    );
                }

                if (changedValues.distance) {
                  updatedValues.distance = changedValues.distance.replace(
                    /[^0-9]/g,
                    ""
                  );
                }

                setValues(updatedValues);
                form.setFieldsValue(updatedValues);
              }}
            >
              <CustomFormItem
                label="Municipalidad"
                name="municipality"
                placeholder="Ingrese la municipalidad"
                maxLength={50}
                tooltipText="Ingrese el nombre de la municipalidad"
                rules={[
                  {
                    required: true,
                    message: "Municipalidad no válida",
                    pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                  },
                ]}
                iconSuffix="🏛️"
                validateStatus={formErrors.municipality ? "error" : ""}
                help={formErrors.municipality}
              />

              <CustomFormItem
                label="Distancia"
                name="distance"
                placeholder="Ingrese la distancia"
                maxLength={10}
                tooltipText="Ingrese la distancia en kilómetros"
                rules={[
                  {
                    required: true,
                    message: "Distancia no válida",
                    pattern: /^[0-9]+$/,
                  },
                ]}
                showCounter
                iconSuffix="📏"
                validateStatus={formErrors.distance ? "error" : ""}
                help={formErrors.distance}
              />
            </Form>
          </div>
        }
        icon={<AddIcon />}
        className="icon-style"
        title="Agregar Distancia de Shopify"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        PlusCircleOutlined={<PlusCircleOutlined />}
        form={form}
      />
    </div>
  );
};

export default NewShopifyDistances;
