import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, message } from "antd";
import {
  DeleteOutlined,
  UserDeleteOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import InterfaceTable from "../../Table/InterfaceTable";
import { getsetCollaboratorsSelector } from "../Infrastructure/Collaborators.reducer";
import {
  getByCompany,
  deleteCollaborator,
  revokeCollaborator,
} from "../Application/Collaborators.business";
import Storage from "../../Data/Domain/Storage";

const CollaboratorBoard = () => {
  const collaborators = useSelector(getsetCollaboratorsSelector);
  const idCompany = new Storage("id").getItem();

  useEffect(() => {
    getByCompany(idCompany);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id) => {
    try {
      Modal.confirm({
        title: "Está seguro de que desea eliminar un colaborador?",
        okText: "Si",
        okType: "danger",
        onOk: () => {
          deleteCollaborator(id);
          message.success("Se eliminó el colaborador");
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };
  const handleRevoke = (id, revoked) => {
    try {
      Modal.confirm({
        title: revoked
          ? "Está seguro de que desea revocar el acceso de este colaborador?"
          : "Quiere habilitar el acceso de este colaborador?",
        okText: "Si",
        okType: "danger",
        onOk: () => {
          revokeCollaborator(id, { revoked });
          revoked
            ? message.success("Se revocó el acceso del colaborador")
            : message.success("Se habilitó el acceso del colaborador");
          getByCompany(idCompany);
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Apellido",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Estado",
      dataIndex: "enabled",
      key: "enabled",
      render: (_, record) => {
        return record.enabled ? <p>Habilitado</p> : <p>Deshabilitado</p>;
      },
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <>
            <DeleteOutlined
              onClick={() => {
                handleDelete(record.id);
              }}
              style={{ color: "red" }}
            />
            {record.enabled && (
              <UserDeleteOutlined
                onClick={() => {
                  handleRevoke(record.id, false);
                }}
                style={{ color: "red" }}
              />
            )}{" "}
            {!record.enabled && (
              <UserAddOutlined
                onClick={() => {
                  handleRevoke(record.id, true);
                }}
                style={{ color: "red" }}
              />
            )}
          </>
        );
      },
    },
  ];
  return <InterfaceTable rowKey="id" data={collaborators} columns={columns} />;
};

export default CollaboratorBoard;
