import { useEffect, useState } from "react";
import { updateEquivalentCodes } from "../Application/EquivalentCodes.business";
import Modal from "../../Modal/Modal";
import { Form, message } from "antd";
import {
  EditOutlined,
  CalendarOutlined,
  VerticalAlignTopOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import "./EquivalentCodes.css";
import { EditIcon } from "../../Utils/UI/Text";
import CustomError from "../../Utils/Domain/CustomError";
import { CustomFormItem } from "../../Utils/UI/Text";
const EquivalentCodesDetail = ({ record, refreshData }) => {
  const [values, setValues] = useState(record);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setValues(record);
  }, [record]);

  const handleUpdate = async () => {
    const data = {
      code: values.code,
      equivalence: values.equivalence,
      year: values.year,
    };
    try {
      setLoading(true);

      await updateEquivalentCodes(record.id, data);
      message.success("Código Equivalente actualizado con éxito");
      setLoading(false);
      refreshData();
      updateEquivalentCodes(record.id, data);
      return true;
    } catch (error) {
      const customError = new CustomError(error);

      message.error(customError.message);

      return false;
    }
  };

  return (
    <div>
      <Modal
        children={
          <Form
            name="basic"
            autoComplete="off"
            layout="vertical"
            responsive={"true"}
            initialValues={values}
            onFinish={handleUpdate}
            onValuesChange={(_, allValues) => setValues(allValues)}
          >
            {record ? (
              <>
                <div className="form-group-equivalent-codes">
                  <CustomFormItem
                    label="Código"
                    name="code"
                    placeholder="Ingrese la ubicación"
                    iconSuffix={<CodeOutlined style={{ color: "#52c41a" }} />}
                  />
                </div>
                <div className="form-group-equivalent-codes">
                  <CustomFormItem
                    label="Equivalencia"
                    name="equivalence"
                    placeholder="Ingrese la ubicación"
                    iconSuffix={
                      <VerticalAlignTopOutlined style={{ color: "#52c41a" }} />
                    }
                  />
                </div>
                <div className="form-group-equivalent-codes">
                  <CustomFormItem
                    label="Año"
                    name="year"
                    placeholder="Ingrese la ubicación"
                    iconSuffix={
                      <CalendarOutlined style={{ color: "#52c41a" }} />
                    }
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </Form>
        }
        icon={<EditIcon TooltipEdit="Editar un código equivalente" />}
        title="Editar un código equivalente"
        cancelButtonProps={{ style: { display: "none" } }}
        text="Editar"
        handleSubmit={handleUpdate}
        PlusCircleOutlined={<EditOutlined />}
        form={form}
      />
    </div>
  );
};

export default EquivalentCodesDetail;
