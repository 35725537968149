import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { message, Button, Divider, Input, Select, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  getSelectedCategorySelector,
  getSubcategoriesSelector,
} from "../Infrastructure/Categories.reducer";
import { saveSelectedSubcategory } from "../Infrastructure/Categories.store";
import {
  getCategories,
  getSubcategoriesData,
} from "../Application/Categories.business";
import { postSubcategories } from "../Infrastructure/Categories.service";

const SearcherSubcategories = () => {
  const selectedCategory = useSelector(getSelectedCategorySelector);
  const selectedSubcategories = useSelector(getSubcategoriesSelector);
  const [subcategories, setSubcategories] = useState([]);
  const [name, setName] = useState();
  const inputRef = useRef(null);

  useEffect(() => {
    const subcategories = selectedSubcategories
      ? selectedSubcategories.map((subcategory) => ({
          label: subcategory.name,
          value: subcategory.name,
        }))
      : [];
    setSubcategories(subcategories);
    getCategories();
  }, [selectedSubcategories]);

  const handleChange = (value) => {
    const [subcategory] = selectedSubcategories
      ? selectedSubcategories.filter(
          (subcategory) => subcategory.name === value
        )
      : [];

    saveSelectedSubcategory(subcategory);
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = async (e) => {
    e.preventDefault();
    try {
      await postSubcategories({
        name,
        category: selectedCategory,
      });
      setSubcategories([
        ...subcategories,
        {
          value: name,
          label: name,
        },
      ]);
      setName();
      inputRef.current?.focus();
      getCategories();
      getSubcategoriesData(selectedCategory);
      message.success("Se agregó una nueva Subcategoría");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
  };

  return (
    <>
      <Select
        showSearch
        placeholder="Subcategorías"
        onChange={handleChange}
        style={{ width: "100%" }}
        filterOption={false}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: "8px 0",
              }}
            />
            <Space
              style={{
                padding: "0 8px 4px",
              }}
            >
              {selectedSubcategories ? (
                <>
                  <Input
                    placeholder="Nueva subcategoría"
                    ref={inputRef}
                    value={name}
                    onChange={onNameChange}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    Agregar
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Space>
          </>
        )}
        options={subcategories}
      />
    </>
  );
};

export default SearcherSubcategories;
