import React, { useState, useEffect } from "react";
import { Input, Select, message } from "antd";
import Button from "../../Layout/DesignSystem/Button/Button";
import {
  getOffsets,
  patchManageData,
} from "../../DashboardOffset/Application/DashboardOffset.business";

const NewScopeManageData = (props) => {
  const [scope1, setScope1] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    setButtonDisabled(
      props.userId === undefined ||
        !scope1 ||
        isNaN(parseFloat(quantity)) ||
        quantity === null
    );
  }, [props.userId, scope1, quantity]);

  const handleSelectChange = (value) => {
    setScope1(value);
  };

  const handleUpdate = async () => {
    let data;
    try {
      const quantityValue = parseFloat(quantity);

      data = [
        {
          path: "/scopes",
          op: "replace",
          value: {
            [scope1]: {
              quantity: quantityValue,
            },
          },
        },
      ];

      await patchManageData(props.userId, data);
      getOffsets(props.userId);
      message.success("Se actualizó con éxito");
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
    }
  };

  return (
    <div className="grid-NewManageData">
      <div className="item-NewManageData">
        <Select
          className="select-searcher custom-select"
          placeholder="Seleccionar Scope "
          clearIcon
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleSelectChange}
          options={[
            { value: "scope1", label: "Scope 1" },
            { value: "scope2", label: "Scope 2" },
            { value: "scope3", label: "Scope 3" },
          ]}
        />
      </div>
      <div className="item-NewManageData">
        <Input
          type="number"
          min={0}
          className="input-NewManageData custom-input"
          placeholder="Emisiones"
          value={quantity}
          onChange={(e) => {
            setQuantity(e.target.value);
          }}
        />
      </div>
      <div>
        <Button
          htmlType="submit"
          text="Agregar"
          onClick={handleUpdate}
          block={true}
          disabled={buttonDisabled}
        />
      </div>
    </div>
  );
};

export default NewScopeManageData;
