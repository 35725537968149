import { useState, useEffect } from "react";
import { Form, Row, message } from "antd";
import { CustomFormItem, CustomDatePicker } from "../../Utils/UI/Text";
import Modal from "../../Modal/Modal";
import moment from "moment";
import { EditIcon, CustomFormSelect } from "../../Utils/UI/Text";
import {
  FireOutlined,
  PercentageOutlined,
  CloudDownloadOutlined,
  CloudOutlined,
  DollarCircleOutlined,
  TagOutlined,
  TagsOutlined,
  CalendarOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { updateScriptOffset } from "../Application/ScriptOffsets.business";
import CustomError from "../../Utils/Domain/CustomError";

const ScriptOffsetsDetail = ({ form, record, userId, Offid, refreshData }) => {
  const [values, setValues] = useState(record);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValues(record);
  }, [record]);

  const handleSelectorChange = (value) => {
    setValues({ ...values, scope: value || "" });
  };

  const handleUpdate = async () => {
    setLoading(true);
    const data = {
      consumption: values.consumption,
      factor: parseFloat(values.factor) || 0,
      total_co2_kg: parseFloat(values.total_co2_kg) || 0,
      total_co2_tons: parseFloat(values.total_co2_tons) || 0,
      scope: values.scope || "scope 1",
      amountTotal: parseFloat(values.amountTotal) || 0,
    };

    if (values.accountingDate)
      data.accountingDate = moment(values.accountingDate).format("YYYY-MM-DD");
    if (values.subScope?.trim()) data.subScope = values.subScope;
    if (values.subCategory?.trim()) data.subCategory = values.subCategory;
    if (values.area?.trim()) data.area = values.area;
    if (values.project?.trim()) data.project = values.project;

    try {
      await updateScriptOffset(Offid, data);
      message.success("Script actualizado con éxito");
      refreshData();
      updateScriptOffset(Offid, data);
      return true;
    } catch (error) {
      const customError = new CustomError(error);

      message.error(customError.message);

      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      icon={<EditIcon TooltipEdit="Editar un proyecto" />}
      title="Editar Proyecto"
      cancelButtonProps={{ style: { display: "none" } }}
      text="Editar Proyecto"
      handleSubmit={handleUpdate}
      form={form}
      PlusCircleOutlined={<EditOutlined />}
    >
      <Row type="flex" justify="center" align="middle">
        <Form
          name="basic"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          style={{ width: "100%" }}
          initialValues={values}
          onFinish={handleUpdate}
          onValuesChange={(_, allValues) => setValues(allValues)}
        >
          <div className="grid-form-scriptOffsets">
            <CustomFormItem
              label="Consumo"
              name="consumption"
              placeholder="Ingrese consumo"
              iconSuffix={<FireOutlined style={{ color: "#fa541c" }} />}
            />
            <CustomFormItem
              label="Factor"
              name="factor"
              placeholder="Ingrese factor"
              iconSuffix={<PercentageOutlined style={{ color: "#1890ff" }} />}
            />
            <CustomFormItem
              label="KG Totales CO₂"
              name="total_co2_kg"
              placeholder="Ingrese KG totales"
              iconSuffix={<CloudOutlined style={{ color: "#52c41a" }} />}
            />
            <CustomFormItem
              label="Ton. Totales de CO₂"
              name="total_co2_tons"
              placeholder="Ingrese toneladas"
              iconSuffix={
                <CloudDownloadOutlined style={{ color: "#722ed1" }} />
              }
            />
            <CustomFormSelect
              label="Scope"
              name="scope"
              placeholder="Seleccione el scope"
              options={[
                { value: "scope 1", label: "Scope 1" },
                { value: "scope 2", label: "Scope 2" },
                { value: "scope 3", label: "Scope 3" },
              ]}
              onChange={handleSelectorChange}
              allowClear
              style={{ width: "100%" }}
            />
            <CustomDatePicker
              label="Fecha inicial del contrato"
              name="contract_start_date"
              placeholder="Selecciona la fecha de inicio"
              iconSuffix={<CalendarOutlined style={{ color: "#52c41a" }} />}
            />
            <CustomFormItem
              label="Monto total"
              name="amountTotal"
              placeholder="Ingrese monto total"
              iconSuffix={<DollarCircleOutlined style={{ color: "#faad14" }} />}
            />
            <CustomFormItem
              label="Categoría"
              name="subScope"
              placeholder="Ingrese categoría"
              iconSuffix={<TagOutlined style={{ color: "#1890ff" }} />}
            />
            <CustomFormItem
              label="Subcategoría"
              name="subCategory"
              placeholder="Ingrese subcategoría"
              iconSuffix={<TagsOutlined style={{ color: "#fa541c" }} />}
            />
          </div>
        </Form>
      </Row>
    </Modal>
  );
};

export default ScriptOffsetsDetail;
