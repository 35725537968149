import {
  getScriptOffsetByUserLogic,
  updateScriptOffsetLogic,
} from "./ScriptOffsets.logic";
import {
  saveListScriptOffset,
  saveCountScriptOffset,
} from "../Infrastructure/ScriptOffsets.store";

export async function getScriptOffsetByUser(id, params) {
  try {
    const { count, resources: scriptOffset } = await getScriptOffsetByUserLogic(
      id,
      params
    );
    saveListScriptOffset(scriptOffset);
    saveCountScriptOffset(count);
    return { count, scriptOffset };
  } catch (error) {
    throw error;
  }
}

export async function updateScriptOffset(id, data) {
  try {
    const response = await updateScriptOffsetLogic(id, data);
    return response;
  } catch (error) {
    throw error;
  }
}
