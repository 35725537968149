import { useEffect } from "react";
import { useSelector } from "react-redux";
import InterfaceTable from "../../Table/InterfaceTable";
import { getRepositoryProjectsSelector } from "../Infrastructure/RepositoryProjects.reducer";
import {
  getRepositoryProjects,
  deleteRepositoryProject,
} from "../Application/RepositoryProjects.business";
import { Space, Form, message } from "antd";
import RepositoryProjectDetail from "./RepositoryProjectDetail";
import RepositoryProjectAssociation from "./RepositoryProjectAssociation";
import { DeleteIcon, ModalDeleteConfirm } from "../../Utils/UI/Text";

const RepositoryProjectBoard = () => {
  const repositoryProjects = useSelector(getRepositoryProjectsSelector);

  useEffect(() => {
    getRepositoryProjects();
  }, []);

  const handleDelete = async (id) => {
    try {
      ModalDeleteConfirm({
        title: "¿Está seguro de eliminar un proyecto",
        messageOnOk: "Se eliminó el proyecto",
        onConfirm: () => deleteRepositoryProject(id),
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",

      render: (record) => {
        return (
          <Space size="middle">
            <RepositoryProjectDetail record={record} />

            <RepositoryProjectAssociation record={record} />

            <DeleteIcon
              TooltipDelete="Eliminar un Proyecto "
              onClick={() => handleDelete(record.id)}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <InterfaceTable rowKey="id" data={repositoryProjects} columns={columns} />
  );
};

export default RepositoryProjectBoard;
