import React, { useState } from "react";
import { message, Form } from "antd";
import { postEquivalentCodes } from "../Infrastructure/EquivalentCodes.service";
import { PlusCircleOutlined } from "@ant-design/icons";
import Modal from "../../Modal/Modal";
import { AddIcon, CustomFormItem } from "../../Utils/UI/Text";
import CustomError from "../../Utils/Domain/CustomError";

const NewEquivalentCodes = ({ getEquivalentCodes }) => {
  const [values, setValues] = useState({
    code: "",
    equivalence: "",
    year: "",
  });
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = async () => {
    setLoading(true);

    const { code, equivalence, year } = values;

    try {
      await postEquivalentCodes({ code, equivalence, year });
      getEquivalentCodes();
      message.success("Se agregó un nuevo código equivalente");

      setValues({ code: "", equivalence: "", year: "" });
      form.resetFields();
      return true;
    } catch (error) {
      const customError = new CustomError(error);

      message.error(customError.message);

      if (customError.message.includes("")) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          code: customError.message,
          equivalence: customError.message,
          year: customError.message,
        }));
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        children={
          <div>
            <Form
              className="horizontal-form-equivalent-codes"
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              onValuesChange={(changedValues, allValues) => {
                const updatedValues = { ...allValues };

                if (changedValues.code) {
                  updatedValues.code = changedValues.code.replace(
                    /[^0-9]/g,
                    ""
                  );
                }

                if (changedValues.equivalence) {
                  updatedValues.equivalence = changedValues.equivalence.replace(
                    /[^0-9]/g,
                    ""
                  );
                }

                if (changedValues.year) {
                  updatedValues.year = changedValues.year.replace(
                    /[^0-9]/g,
                    ""
                  );
                }

                setValues(updatedValues);
                form.setFieldsValue(updatedValues);
              }}
            >
              <CustomFormItem
                label="Código"
                name="code"
                placeholder="Ingrese el código"
                maxLength={10}
                tooltipText="Ingrese un código numérico"
                rules={[
                  {
                    required: true,
                    message: "Código no válido",
                    pattern: /^[0-9]+$/,
                  },
                ]}
                iconSuffix="🔢"
                validateStatus={formErrors.code ? "error" : ""}
                help={formErrors.code}
              />

              <CustomFormItem
                label="Equivalencia"
                name="equivalence"
                placeholder="Ingrese la equivalencia"
                maxLength={10}
                tooltipText="Ingrese la equivalencia numérica"
                rules={[
                  {
                    required: true,
                    message: "Equivalencia no válida",
                    pattern: /^[0-9]+$/,
                  },
                ]}
                iconSuffix="💱"
                validateStatus={formErrors.equivalence ? "error" : ""}
                help={formErrors.equivalence}
              />

              <CustomFormItem
                label="Año"
                name="year"
                placeholder="Ingrese el año"
                maxLength={4}
                tooltipText="Ingrese un año en formato numérico"
                rules={[
                  {
                    required: true,
                    message: "Año no válido",
                    pattern: /^[0-9]{4}$/,
                  },
                ]}
                showCounter
                iconSuffix="📅"
                validateStatus={formErrors.year ? "error" : ""}
                help={formErrors.year}
              />
            </Form>
          </div>
        }
        icon={<AddIcon />}
        form={form}
        className="icon-style"
        title="Agregar un Código equivalente"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        PlusCircleOutlined={<PlusCircleOutlined />}
      />
    </div>
  );
};

export default NewEquivalentCodes;
