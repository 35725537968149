import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select, Input, message } from "antd";
import { getCategoriesSelector } from "../../Categories/Infrastructure/Categories.reducer";
import Button from "../../Layout/DesignSystem/Button/Button";
import { patchManageData } from "../../DashboardOffset/Application/DashboardOffset.business";
import { getCategories } from "../../Categories/Application/Categories.business";

const { Option } = Select;

const NewCategoryManageData = (props) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [tonsValue, setTonsValue] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const categories = useSelector(getCategoriesSelector);
  // ;
  useEffect(() => {
    setButtonDisabled(
      props.userId === undefined ||
        !selectedCategoryId ||
        isNaN(parseFloat(tonsValue)) ||
        tonsValue === null
    );
  }, [props.userId, selectedCategoryId, tonsValue]);

  useEffect(() => {
    getCategories();
  }, []);

  const onSearch = (val) => {
    getCategories({ filter: { q: val } });
  };

  const onChange = async (id) => {
    setSelectedCategoryId(id);
  };
  const handleUpdate = async () => {
    let data;
    const tons = parseFloat(tonsValue);

    try {
      data = [
        {
          op: "add",
          path: "/categories_emission",
          value: {
            category: selectedCategoryId,
            tons,
          },
        },
      ];

      await patchManageData(props.userId, data);
      message.success("Se actualizó con éxito");
    } catch (error) {
      message.error("Se produjo un error, vuelve a intentarlo");
    }
  };
  return (
    <div className="grid-NewManageData">
      <div className="item-NewManageData">
        <Select
          onSearch={onSearch}
          showSearch
          placeholder="Seleccionar categorías"
          onChange={onChange}
          style={{ width: "100%" }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {categories?.map((category) => (
            <Option value={category.id} key={category.id}>
              {`${category.name} | ${category.scope}`}
            </Option>
          ))}
        </Select>
      </div>
      <div className="item-NewManageData">
        <Input
          type="number"
          min={0}
          className="input-NewManageData custom-input"
          placeholder="Emisiones(tons)"
          onChange={(e) => {
            setTonsValue(e.target.value);
          }}
        />
      </div>
      <div>
        <Button
          htmlType="submit"
          text="Agregar"
          onClick={handleUpdate}
          block={true}
          disabled={buttonDisabled}
        />
      </div>
    </div>
  );
};

export default NewCategoryManageData;
